import { useEffect, useState } from 'react';
import { Field } from '@/isomorphic/components/forms/components/field';
import { useAccountNumbeValidation } from '../hooks/use-account-number-validation';

type AccountValues = {
    bankName: string;
    clearingNumber: string;
    accountNumber: string;
};

type Props = {
    values: AccountValues;
    labels: AccountValues;
    bankFieldDisabled?: boolean;
    setValues: (args: AccountValues) => void;
    accountNumberValidatedCallback: (isValidated: boolean) => void;
};

export const AccountClearingNumber = ({ values, setValues, labels, accountNumberValidatedCallback, bankFieldDisabled = false }: Props) => {
    const sanitizeNumber = (value: string) => value?.replace(/[^\d]+/g, '');
    const { validateAccountNumber, getBankName, validatingAccount, fetchingBankName } = useAccountNumbeValidation();

    const [bankName, setBankName] = useState(() => ({
        bankName: values.bankName,
        clearingNumber: values.clearingNumber,
    }));
    const [validAccount, setValidAccount] = useState(() => ({
        isValid: false,
        accountNumber: values.accountNumber,
    }));
    const [isAccountNumberValid, setIsAccountNumberValid] = useState(() => {
        if (values.clearingNumber && values.accountNumber) {
            validateAccountDetails(values.clearingNumber, values.accountNumber);
        }
        return false;
    });

    useEffect(() => {
        accountNumberValidatedCallback(isAccountNumberValid);
    }, [isAccountNumberValid]);

    async function fetchBankName(clearingNumber: string) {
        if (fetchingBankName || (bankName.clearingNumber === clearingNumber && bankName.bankName)) {
            return;
        }

        const apiBankNameResponse = await getBankName(sanitizeNumber(clearingNumber));

        setBankName({ clearingNumber: values.clearingNumber, bankName: apiBankNameResponse ?? '' });
        setValues({ ...values, bankName: apiBankNameResponse ?? '' });
    }

    async function validateAccountDetails(clearingNumber: string, accountNumber: string) {
        if (validatingAccount) {
            return;
        }

        if (validAccount.accountNumber === accountNumber && validAccount.isValid) {
            setIsAccountNumberValid(true);
            return;
        }

        const isValid = await validateAccountNumber(sanitizeNumber(clearingNumber), sanitizeNumber(accountNumber));

        setValidAccount({ accountNumber, isValid });
        setIsAccountNumberValid(isValid);
    }

    const updateClearingNumber = (clearingNumber: string) => {
        let value = clearingNumber;
        if (value.length === 5 && /^\d+$/.test(value)) {
            value = value.slice(0, 4) + '-' + value.slice(4);
        }

        setValues({ ...values, clearingNumber });
    };

    return (
        <div className="">
            <Field
                grouped="top"
                value={values.clearingNumber}
                label={labels.clearingNumber}
                onChange={updateClearingNumber}
                validation={() => !!values.bankName}
                onBlur={clearingNumber => fetchBankName(clearingNumber)}
            />
            <Field
                grouped="middle"
                value={values.accountNumber}
                label={labels.accountNumber}
                validation={() => isAccountNumberValid}
                onChange={accountNumber => setValues({ ...values, accountNumber })}
                onBlur={accountNumber => validateAccountDetails(values.clearingNumber, accountNumber)}
            />
            <Field
                grouped="bottom"
                value={values.bankName}
                label={labels.bankName}
                onChange={() => () => null}
                disabled={bankFieldDisabled}
                validation={() => !!values.bankName}
            />
        </div>
    );
};
