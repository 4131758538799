import { useContext, useMemo } from 'react';
import { PropsType } from '../types';
import { AppContext } from '@/contexts/app-context';

export const formatInterest = (interest: string) => (interest.includes('%') ? interest : `${interest}%`);

export const Circle = ({
    getLabel,
    products,
    fetchProductInformation = false,
    fields,
}: Pick<PropsType, 'getLabel' | 'products' | 'fetchProductInformation' | 'fields'>) => {
    const { locale } = useContext(AppContext);

    const interest = useMemo(() => {
        const activeProduct = fetchProductInformation && products?.length ? products.find(product => product?.code === fields.productCode) : null;
        const interestRate = activeProduct
            ? Intl.NumberFormat(locale, { minimumFractionDigits: 2 }).format(activeProduct.interest.rate)
            : getLabel('interestRate');

        return formatInterest(interestRate);
    }, [fetchProductInformation, products, getLabel, locale]);

    return (
        <div className="r-flex r-h-[200px] r-w-[200px] r-flex-col r-items-center r-justify-center r-space-y-2 r-rounded-full r-bg-primary-300 r-font-semibold r-text-white">
            <p>{getLabel('interest')}</p>
            <p className="r-text-3xl">{interest}</p>
        </div>
    );
};
