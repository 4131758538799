import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ProgressBar } from './progress-bar';
import { StepOne } from './step-one';
import { StepTwo } from './step-two';
import { StepThree } from './step-three';
import { StepFour } from './step-four';
import { StepFive } from './step-five';
import { AppContext, AppContextType } from '@/contexts/app-context';
import { BankOpenSavingsFormProps, FieldsType, Labels } from './types';
import { useKycSurvey } from '@/hooks/use-kyc-survey';
import { useGtm } from '@/hooks/use-gtm';
import { StepZero } from './step-zero';
import { useGetProducts } from '@/hooks/use-get-products';
import { KYCResponse } from '@/queries/kyc-survey';
import { useLocalization } from '@/hooks/use-localization';
import { useLogout } from '@/hooks/use-logout';

type KycValues = Record<string, Record<number, string[]>>;

export type PropsType = {
    step: number;
    labels: Labels;
    values: FieldsType;
    showAutoGiro?: boolean;
    user: AppContextType['user'];
    setStep: (step: number) => void;
    kycResponse: KYCResponse | undefined;
    setValues: (values: FieldsType) => void;
    depositGuaranteePopupText: { children: string };
    handleChange: (value: string | boolean, name: keyof FieldsType) => void;
    kycValues: Record<string, Record<number, Array<string | number | boolean>>>;
    handleKycChange: (groupId: string, questionId: number, optionText: string, multiple: boolean, isChecked?: boolean) => void;
};

const initialValues: FieldsType = {
    name: '',
    streetAddress: '',
    zipCode: '',
    city: '',
    productCode: '',
    phoneDay: '',
    phoneMobile: '',
    contactEmail: '',
    clearingNumber: '',
    accountNumber: '',
    bankName: '',
    fixedValue: '',
    directDebitDay: '',
    directDebitAmount: '',
    directDebitBankName: '',
    directDebitClearingNumber: '',
    directDebitAccountNumber: '',
    acceptDepositGuarantee: false,
    acceptCommonTerms: false,
};

export const specificPropsTruthy = (obj: FieldsType, requiredProps: Array<keyof FieldsType>) => requiredProps.every(key => Boolean(obj[key]));

export const BankOpenSavingsForm = ({
    labels,
    redirectLink,
    depositGuaranteePopupText,
    enableOpenBanking = false,
    fetchAccountInformation = false,
    fetchProductInformation = false,
    fakeEmptyRedirectLinkResponse = false,
}: BankOpenSavingsFormProps) => {
    const context = useContext(AppContext);
    const location = useLocation();
    const { logout } = useLogout();
    const [searchParams] = useSearchParams();
    const { sendPageInteraction } = useGtm();
    const { localize } = useLocalization();
    const containerRef = useRef<HTMLDivElement>(null);
    const { data: kycSurveyData, isLoading } = useKycSurvey();
    const { data: fetchedProducts, isLoading: productsLoading } = useGetProducts(context, fetchProductInformation);

    const [step, setStep] = useState(1);
    const [values, setValues] = useState<FieldsType>(initialValues);
    const [kycValues, setKycValues] = useState<Record<string, Record<string, string[]>>>({});
    const handleChange = (value: string | boolean, name: keyof FieldsType) => setValues(prev => ({ ...prev, [name]: value }));

    useEffect(() => {
        const isAuthenticated = context?.user?.isAuthenticated ?? false;
        const progress = !isAuthenticated ? 1 : Number(sessionStorage.getItem('progress') ?? 1);
        setStep(progress);

        if (isAuthenticated) {
            const kycValues = sessionStorage?.getItem('kycValues');
            if (kycValues) {
                setKycValues(JSON.parse(sessionStorage.getItem('kycValues') || '{}'));
            }

            const formValues = sessionStorage?.getItem('formValues');
            if (formValues) {
                setValues(JSON.parse(sessionStorage.getItem('formValues') || '{}'));
            }
        }
    }, [context?.user?.isAuthenticated]);

    useEffect(() => {
        if (!kycSurveyData || isLoading) {
            return;
        }

        const applicant = 'result' in kycSurveyData ? kycSurveyData?.result?.applicant : null;
        if (!applicant) {
            return;
        }

        const [firstAddress] = applicant.addresses;
        setValues({
            ...values,
            name: applicant.name,
            streetAddress: firstAddress?.street || '',
            zipCode: firstAddress?.zipCode || '',
            city: firstAddress?.city || '',
            phoneMobile: values.phoneMobile,
        });
    }, [kycSurveyData]);

    useEffect(() => {
        // Save to session storage every time values change
        sessionStorage.setItem('formValues', JSON.stringify(values));
    }, [values]);

    useEffect(() => {
        if (step !== 4.5) {
            sessionStorage.setItem('progress', JSON.stringify(step));
        }
    }, [step]);

    useEffect(() => {
        if (searchParams.has('productCode') && values.productCode === '') {
            handleChange(searchParams.get('productCode') as string, 'productCode');
        }
    }, [searchParams]);

    useEffect(() => {
        if (searchParams.get('response') === 'success') {
            sessionStorage.removeItem('formValues');
            sessionStorage.removeItem('progress');
            sessionStorage.removeItem('kycValues');
            setValues(initialValues);
        }
    }, [searchParams]);

    function getCurrentProductCode() {
        if (values.productCode) {
            return values.productCode;
        }

        return searchParams.has('productCode') ? searchParams.get('productCode') : '';
    }

    const stepCallback = (step: number) => {
        setStep(step);

        if (!context?.user?.isAuthenticated) {
            return;
        }

        if (step < 5) {
            sendPageInteraction({ action: 'Open savings account', label: `Step ${step}`, productCode: getCurrentProductCode() });
        }

        if (containerRef?.current) {
            window.scrollTo({
                behavior: 'smooth',
                top: containerRef.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 250,
            });
        }
    };

    const showAutoGiro = useMemo(() => {
        if (!values.productCode) {
            return true;
        }

        const selectedProduct = fetchedProducts?.find(product => product.code === values.productCode);
        if (!selectedProduct) {
            return true;
        }

        return selectedProduct.interest.type === 'VARIABLE';
    }, [fetchedProducts, values.productCode]);

    const handleKycChange = (groupId: string, questionId: number, optionText: string, multiple = false, isChecked?: boolean) => {
        setKycValues((prevKycValues: KycValues) => {
            const prevGroup = prevKycValues[groupId] || {};
            const prevAnswers = prevGroup[questionId] || [];

            let updatedAnswers: string[] = [];

            if (multiple) {
                if (isChecked === true) {
                    updatedAnswers = [...prevAnswers, optionText];
                } else if (isChecked === false) {
                    updatedAnswers = prevAnswers.filter(prevValue => prevValue !== optionText);
                }
            } else {
                updatedAnswers = [optionText];
            }

            const updatedGroup = { ...prevGroup, [questionId]: [...new Set(updatedAnswers)] };
            const updatedKycValues = { ...prevKycValues, [groupId]: updatedGroup };

            if (typeof window !== 'undefined') {
                sessionStorage.setItem('kycValues', JSON.stringify(updatedKycValues));
            }

            return updatedKycValues;
        });
    };

    const handleLogin = () => {
        let redirectUrl = `${context.url}${location.pathname}`;
        if (searchParams.has('productCode')) {
            redirectUrl += `?productCode=${searchParams.get('productCode')}`;
        }

        window.location.href = `/login?customRedirectUrl=${redirectUrl}&scope=depositsApplicationScope`;
    };

    const renderStepContent = () => {
        const props: PropsType = {
            labels,
            handleChange,
            setValues: (values: FieldsType) => setValues(values),
            values,
            setStep: stepCallback,
            user: context.user,
            kycResponse: undefined,
            handleKycChange,
            kycValues,
            depositGuaranteePopupText,
            step,
        };

        if (kycSurveyData && 'statusCode' in kycSurveyData && kycSurveyData.statusCode !== 200) {
            return (
                <div className="r-py-4">
                    <div className="r-space-y-4 r-border-l-8 r-border-l-red-600 r-py-4 r-pl-4">
                        <h3 className="r-text-2xl r-font-bold">{labels.under18ErrorHeader || 'Det uppstod ett fel'}</h3>
                        <div
                            className="r-prose"
                            dangerouslySetInnerHTML={{
                                __html:
                                    kycSurveyData.statusCode === 451
                                        ? labels.under18ErrorText ||
                                          `<p>Du måste vara minst 18 år för att ansöka om ett sparkonto. Vänligen be en vårdnadshavare om hjälp eller återkom när du har fyllt 18 år.</p>`
                                        : labels.genericErrorText ||
                                          `<p>Vi kan tyvärr inte hantera din ansökan just nu.<br />Vänligen kontakta kundtjänst på <a href="tel:+4622563733">22 56 37 33</a>.</p>`,
                            }}
                        />
                        <p className="r-py-4">
                            <a
                                className="r-rounded-full r-bg-primary r-px-6 r-py-3 r-text-sm r-font-bold r-text-white"
                                href="/"
                                onClick={() => logout()}
                            >
                                {localize('close')}
                            </a>
                        </p>
                    </div>
                </div>
            );
        }

        props.kycResponse = kycSurveyData as KYCResponse;

        switch (fetchProductInformation ? step - 1 : step) {
            case 0:
                return <StepZero {...props} fetchedProducts={fetchedProducts} isLoading={productsLoading} login={handleLogin} />;
            case 1:
                return <StepOne {...props} hasStepZeroFeature={fetchProductInformation} login={handleLogin} />;
            case 2:
                return <StepTwo {...props} showAutoGiro={showAutoGiro} />;
            case 3:
                return <StepThree {...props} />;
            case 4:
            case 4.5:
                return <StepFour {...props} fakeEmptyRedirectLinkResponse={fakeEmptyRedirectLinkResponse} products={fetchedProducts} />;
        }

        return null;
    };

    return (
        <div data-component="bank-open-savings-form" className="r-mx-auto r-mt-16 r-flex r-w-full r-max-w-2xl r-flex-col">
            {searchParams.get('response') === 'success' ? (
                <StepFive
                    labels={labels}
                    redirectLink={redirectLink}
                    enableOpenBanking={enableOpenBanking}
                    fetchAccountInformation={fetchAccountInformation}
                />
            ) : (
                <div ref={containerRef} className="r-mb-4 r-space-y-6">
                    <h3 className="r-pb-4 r-text-3xl r-font-bold r-text-primary-500">{labels.formTitle}</h3>
                    <ProgressBar totalSteps={fetchProductInformation ? 5 : 4} step={step} label={labels.toSigning} />
                    <div className="r-space-y-4 r-pt-4">{renderStepContent()}</div>
                </div>
            )}
        </div>
    );
};
