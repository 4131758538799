import { useState } from 'react';

export function useAccountNumbeValidation() {
    const [fetchingBankName, setFetcingBankName] = useState(false);
    const [validatingAccount, setValidatingAccount] = useState(false);

    const getBankName = async (clearingNumber: string) => {
        try {
            setFetcingBankName(true);
            const request = await fetch('/api/bank/savings-account-form/bban/bank-name', {
                headers: {
                    'content-type': 'application/json',
                },
                credentials: 'include',
                method: 'POST',
                body: JSON.stringify({
                    clearingNumber,
                }),
            });
            setFetcingBankName(false);

            const response = (await request.json()) as {
                response: {
                    bankName: string;
                };
                status: string;
            };

            return response?.response?.bankName || null;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const validateAccountNumber = async (clearingNumber: string, accountNumber: string) => {
        try {
            setValidatingAccount(true);
            const request = await fetch('/api/bank/savings-account-form/bban/validate', {
                headers: {
                    'content-type': 'application/json',
                },
                credentials: 'include',
                method: 'POST',
                body: JSON.stringify({
                    clearingNumber,
                    accountNumber,
                }),
            });
            setValidatingAccount(false);

            return request?.status === 200;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    return {
        getBankName,
        fetchingBankName,
        validatingAccount,
        validateAccountNumber,
    };
}
