import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { Product } from '@/queries/kyc-survey';
import { Checkbox } from '../../forms/components/checkbox';
import { Button } from '../../typography/button/button';
import { Modal } from '../../modal/modal';
import { useBankOpenSavingsForm } from './hooks/use-bank-open-savings-form';
import { useLogout } from '@/hooks/use-logout';
import { useGtm } from '@/hooks/use-gtm';
import { useLocalization } from '@/hooks/use-localization';
import { PropsType } from './bank-open-savings-form';
import { Arrow } from './components/svg';

type Props = PropsType & {
    fakeEmptyRedirectLinkResponse?: boolean;
    products?: Product[];
};

export const StepFour = ({
    labels,
    kycResponse,
    handleChange,
    values,
    depositGuaranteePopupText,
    kycValues,
    step,
    setStep,
    fakeEmptyRedirectLinkResponse = false,
    products,
}: Props) => {
    const { logout } = useLogout();
    const { localize } = useLocalization();
    const { sendPageInteraction } = useGtm();
    const [isOpen, setIsOpen] = useState(false);
    const { submit } = useBankOpenSavingsForm();

    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [noSignUrl, setNoSignUrl] = useState(false);

    useEffect(() => {
        if (noSignUrl) {
            sendPageInteraction({ action: 'Open savings account', label: 'No Signing Link', productCode: values.productCode });
        }

        return () => {
            logout();
        };
    }, [noSignUrl]);

    const readInformation = () => {
        setIsOpen(false);
        handleChange(true, 'acceptDepositGuarantee');
    };

    const documents = kycResponse?.result.documents;

    const onSubmit = async () => {
        setIsLoading(true);
        const response = await submit(values, kycValues, fakeEmptyRedirectLinkResponse);
        setIsLoading(false);

        if (response?.signUrl === null) {
            setStep(4.5);
            setNoSignUrl(true);
            return;
        }

        if (!response?._links || response?.message === 'Validation failed') {
            setShowError(true);
        }
    };

    const savingsAccountInformation = useMemo(() => {
        if (!values.productCode) {
            return documents?.savingsAccountInformation;
        }
        const chosenProduct = products?.find(product => product.code === values.productCode);
        return chosenProduct?._links?.savings_account_information.href || documents?.savingsAccountInformation;
    }, [values, products, documents]);

    const getErrorMessage = () => {
        if (noSignUrl) {
            return (
                labels?.noSignUrlError ||
                `<p>Hej!</p>
<p>Vi har tyvärr inte möjlighet att hantera din ansökan digitalt, utan hänvisar till ansökan via blankett. Du hittar blanketten <a target="_blank" href="https://documenthandler.resurs.com/Dokument.pdf?customerType=NATURAL&docType=spara-privat-oppna-sparkontomax&land=SE&language=sv">här</a>.</p>
<p>Om du har fler frågor kan du kontakta vår kundservice.</p>
<p>Vänliga hälsningar,<br />
Resurs</p>`
            );
        }

        return (
            labels?.signingError ||
            '<p>Vi kan tyvärr inte hantera din ansökan just nu.<br />Vänligen kontakta kundtjänst på <a href="tel:+4622563733">22 56 37 33</a>.</p>'
        );
    };

    return noSignUrl || showError ? (
        <div className="r-relative r-pt-8">
            <div className="r-space-y-4 r-border-l-8 r-border-l-red-600 r-py-2 r-pl-6">
                <img src="/assets/images/business-loan/manual-application.svg" className="r-w-20" />
                <div className="r-space-y-3 r-pt-2 r-font-semibold">
                    <h3 className="r-text-3xl">{localize('error-generic').replace(/\.$/, '')}</h3>
                </div>
                <div className="r-prose" dangerouslySetInnerHTML={{ __html: getErrorMessage() }} />
            </div>
        </div>
    ) : (
        <>
            <div className="r-mt-4 r-space-y-2">
                <p className="r-text-2xl r-font-bold r-text-primary-400">
                    {step}. {labels.formStepFourTitle}
                </p>
                <p>{labels.formStepFourDescription}</p>
                <div className="r-border-b r-pb-10 r-pt-4">
                    <Checkbox
                        onChange={v => handleChange(v, 'acceptCommonTerms')}
                        checked={values.acceptCommonTerms}
                        label={
                            <>
                                <p className="styled-link">
                                    {labels.signCommonTermsCheckboxText}{' '}
                                    <a href={savingsAccountInformation} target="_blank" rel="noreferrer">
                                        {labels.signCommonTermsLinkText}
                                    </a>
                                    {' & '}
                                    <a href={documents?.commonTermsSavingsAccount} target="_blank" rel="noreferrer">
                                        {labels.signCommonTermsSecondLinkText}
                                    </a>
                                </p>
                            </>
                        }
                    />
                </div>
                <div className="r-border-b r-pb-10 r-pt-6">
                    <Checkbox
                        onChange={() => setIsOpen(true)}
                        checked={values.acceptDepositGuarantee}
                        label={
                            <>
                                <p className="styled-link">
                                    {labels.signDepositGuaranteeCheckboxText}{' '}
                                    <a href={documents?.depositGuarantee} target="_blank" rel="noreferrer">
                                        {labels.signDepositGuaranteeLinkText}
                                    </a>
                                </p>
                            </>
                        }
                    />
                </div>
            </div>
            <div
                className={clsx('r-mt-8 r-border-l-8 r-border-red-600 r-p-2 r-pl-4 r-text-xl r-font-bold', {
                    'r-hidden': !showError,
                })}
            >
                {labels.signErrorMessage}
            </div>

            <div
                className="styled-link"
                dangerouslySetInnerHTML={{
                    __html:
                        labels.gdpr ||
                        `<p>Resurs Bank är personuppgiftsansvarig och behandlar dina personuppgifter.
                            <a href="https://www.resursbank.se/om-oss/integritet-och-sakerhet/gdpr-som-kund">
                                Läs mer om vår behandling och dina rättigheter här
                            </a>.
                        </p>`,
                }}
            />

            {fakeEmptyRedirectLinkResponse ? (
                <div className="r-border-l-8 r-border-danger-400 r-pl-4">
                    <p className="r-mb-4 r-mt-8">
                        <strong>OBS!</strong> Flödet har konfigurerats att alltid returnera en tom redirect URL för signering. Vill du köra hela
                        flödet inklusive signering se till att avaktivera den inställningen i CMS:et!
                    </p>
                </div>
            ) : null}

            <div className="r-flex r-justify-center r-gap-4 r-pt-8 md:r-gap-8">
                <Button type="secondary" fullWidth onClick={() => setStep(step - 1)}>
                    <div className="r-flex r-space-x-1">
                        <Arrow className="r-w-4 r-rotate-180" />
                        <p>{labels.back}</p>
                    </div>
                </Button>
                <Button fullWidth onClick={() => onSubmit()} disabled={!values.acceptCommonTerms || !values.acceptDepositGuarantee || isLoading}>
                    <div className="r-flex r-w-full r-space-x-2">
                        <p>{labels.submit}</p>
                        <img className="r-justify-self-end" src="/assets/icons/bankid-se.svg" />
                    </div>
                </Button>
            </div>
            <div className="r-space-y-0">
                <Modal
                    open={isOpen}
                    disableBackdropClick
                    onClose={() => setIsOpen(false)}
                    actions={
                        <div className="r-flex r-w-full r-flex-col r-space-y-2 r-py-2 md:r-flex-row md:r-space-x-6 md:r-space-y-0">
                            <Button onClick={readInformation} fullWidth className="r-w-full">
                                {labels.signDepositGuaranteeButtonText}
                            </Button>
                        </div>
                    }
                >
                    <div className="r-max-w-[600px]" dangerouslySetInnerHTML={{ __html: depositGuaranteePopupText.children }} />
                </Modal>
            </div>
        </>
    );
};
