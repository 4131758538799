import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { AppContextType } from '@/contexts/app-context';
import { Button } from '../../typography/button/button';
import { FieldsType, Labels } from './types';
import { Arrow } from './components/svg';
import { Product } from '@/queries/kyc-survey';
import { Loading } from '../bank-open-savings-form-business/components/svg';
import { useLocalization } from '@/hooks/use-localization';
import { useGtm } from '@/hooks/use-gtm';

type Props = {
    handleChange: (value: string | boolean, name: keyof FieldsType) => void;
    isLoading: boolean;
    labels: Labels;
    login: () => void;
    fetchedProducts?: Product[];
    setStep: (step: number) => void;
    step: number;
    user: AppContextType['user'];
    values: FieldsType;
};

export const StepZero = ({ handleChange, isLoading, labels, login, fetchedProducts, setStep, step, user, values }: Props) => {
    const { getLocale } = useLocalization();
    const { sendPageInteraction } = useGtm();
    const [disabled, setDisabled] = useState(true);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

    const { productCode } = values;

    const handleProductChoice = (code: string) => {
        sendPageInteraction({ action: 'Open savings account', label: 'Product Select', productCode: code });
        setNextBtnDisabled(false);
        handleChange(code, 'productCode');
    };

    useEffect(() => {
        if (user?.isAuthenticated) {
            setDisabled(false);
        }
    }, [user]);

    useEffect(() => {
        if (productCode) {
            setNextBtnDisabled(false);
        }
    }, [productCode]);

    function renderInterestRate(product: Product) {
        const interestType = product.interest.type === 'VARIABLE' ? labels?.variableInterestRate || 'rörlig' : labels?.fixedInterestRate || 'fast';
        const interestRate = Intl.NumberFormat(getLocale(), { minimumFractionDigits: 2 }).format(product.interest.rate);

        return (
            <>
                {interestRate}% {interestType} {labels?.interestRate || 'ränta'}
            </>
        );
    }

    return (
        <>
            <div className="r-mb-4 r-mt-4 r-space-y-6">
                <p className="r-text-2xl r-font-bold r-text-primary-400">
                    {step}. {labels.formStepZeroTitle || 'Välj sparkonto'}
                </p>
                {disabled && (
                    <Button type="primary" fullWidth onClick={() => login()}>
                        <div className="r-flex r-space-x-1">
                            <p> {labels.formLoginButtonText}</p>
                            <img src="/assets/icons/bankid-se.svg" alt="" />
                        </div>
                    </Button>
                )}
            </div>

            <div
                className={clsx('r-space-y-4', {
                    'r-pointer-events-none r-opacity-30': disabled,
                })}
            >
                <h4 className="r-text-xl r-font-semibold">{labels.formStepZeroHeading || 'Välj sparkontot som passar dina behov'}</h4>
                <div
                    className="styled-link"
                    dangerouslySetInnerHTML={{
                        __html: labels?.formStepZeroDescription || '',
                    }}
                />

                {isLoading && (
                    <div className="r-flex r-min-h-24 r-flex-col r-items-center r-justify-center" role="status">
                        <Loading />
                    </div>
                )}

                {fetchedProducts && fetchedProducts.length > 0 && (
                    <div className="r-flex r-flex-col r-gap-4">
                        {fetchedProducts.map(product => (
                            <button
                                key={product.code}
                                className={clsx(
                                    'r-flex r-flex-col r-gap-2 r-rounded-xl r-border r-border-primary-300 r-p-4 r-ring r-transition-all r-duration-100 hover:r-ring-[2px] hover:r-ring-primary-300 focus:r-ring-[2px] focus:r-ring-primary-300',
                                    {
                                        'r-ring-[2px] r-ring-primary-300': product.code === productCode,
                                        'r-ring-transparent': product.code !== productCode,
                                    }
                                )}
                                onClick={() => handleProductChoice(product.code)}
                            >
                                <div className="r-flex r-w-full r-flex-row r-justify-between">
                                    <h4 className="r-text-xl r-font-semibold">{product.name}</h4>
                                    <div className="r-flex r-items-center r-rounded-md r-bg-[#bfdfde] r-p-2 r-text-xs r-font-light r-text-primary-300">
                                        <p className="r-mb-0">{renderInterestRate(product)}</p>
                                    </div>
                                </div>
                                <p className="r-text-left">{product.description}</p>
                            </button>
                        ))}
                    </div>
                )}

                <Button onClick={() => setStep(2)} fullWidth disabled={nextBtnDisabled} type="tertiary">
                    <div className="r-flex r-items-center r-gap-2">
                        <p>
                            {labels.continue} {step + 1}
                        </p>
                        <Arrow className="r-w-4" />
                    </div>
                </Button>
            </div>
        </>
    );
};
