import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { AppContext } from '@/contexts/app-context';
import { useGtm } from '@/hooks/use-gtm';
import { useLogout } from '@/hooks/use-logout';
import { Intro } from './components/steps/intro';
import { StepOne } from './components/steps/step-one';
import { StepTwo } from './components/steps/step-two';
import { StepThree } from './components/steps/step-three';
import { StepFour } from './components/steps/step-four';
import { StepFive } from './components/steps/step-five';
import { ProgressBar } from './components/progress-bar';
import { Complete } from './components/steps/complete';
import { LabelsType, getLabel } from './labels';
import { ManualApplication } from './components/manual-application';
import { useKyc } from './hooks/use-kyc';
import { useValues } from './hooks/use-values';
import { FieldValuesType, OrganizationType } from './types';
import { useLogin } from '@/hooks/use-login';
import { ApplicationResponseType, useSubmitApplication } from './hooks/use-submit-application';
import { ApplicationError } from './components/application-error';
import { ApplicationExists } from './components/application-exists';
import { useProducts } from './hooks/use-products';
import { Steps } from './components/steps/step-layout';

export type BankOpenSavingsFormBusinessProps = {
    labels: LabelsType;
    backLink?: {
        url: string;
        title: string;
    } | null;
    allowSoleTraders?: boolean;
    chain?: string;
    fetchProductInformation?: boolean;
};

export const BankOpenSavingsFormBusiness = ({
    labels,
    chain,
    backLink = null,
    allowSoleTraders = false,
    fetchProductInformation = false,
}: BankOpenSavingsFormBusinessProps) => {
    const kyc = useKyc();
    const { logout } = useLogout();
    const location = useLocation();
    const context = useContext(AppContext);
    const { sendPageInteraction } = useGtm();
    const { getLoginHintQuery } = useLogin();
    const { submit } = useSubmitApplication();
    const [showError, setShowError] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasExistingApplication, setHasExistingApplication] = useState(false);
    const [selectedSignatoryGroup, setSelectedSignatoryGroup] = useState<null | number>(null);
    const [corporateRegistryDetails, setCorporateRegistryDetails] = useState<Omit<OrganizationType, 'organizationName' | 'organizationTypeCode'>>({
        allSignatories: [],
        beneficialOwners: [],
        combinations: [],
    });
    const { isLoading, products } = useProducts({
        chain: chain || '54',
        fetchProducts: fetchProductInformation && (context.user?.isAuthenticated ?? false),
    });
    const { values, setValues, step, setStep, manualApplicationOnly, setManualApplicationOnly, getValuesFromStorage } =
        useValues(fetchProductInformation);

    useEffect(() => {
        if (containerRef?.current) {
            window.scrollTo({
                behavior: 'smooth',
                top: containerRef.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 250,
            });
        }
    }, [step, context.user]);

    useEffect(() => {
        if (manualApplicationOnly || hasExistingApplication || showError) {
            // This is not exactly the best solution, but there is a race
            // condition here that will overwrite the reset.
            setTimeout(() => {
                resetCompanyData();
            }, 750);
        }
    }, [manualApplicationOnly, hasExistingApplication, showError]);

    const resetCompanyData = () =>
        setValues({
            ...values,
            company: {
                ...values.company,
                organizationName: '',
                organizationType: '',
                governmentId: {
                    ...values.applicant.governmentId,
                    id: '',
                },
            },
        });

    const handleLogin = async (ssn: string | number) => {
        const { loginHint } = await getLoginHintQuery(ssn);

        const queryParams = queryString.stringify({
            loginHint,
            scope: 'depositsBusinessApplicationScope',
            customRedirectUrl: `${context.url}${location.pathname}`,
        });

        window.location.href = `/login?${queryParams}`;
    };

    const onSubmit = async () => {
        if (isSubmitting) {
            return;
        }

        try {
            setIsSubmitting(true);
            const response = await submit(values, kyc);
            // I'll leave this here for test - the same company can't apply more than once
            // even in integration, so to avoid all that we can fake the OK locally.
            // const response = {
            //     status: 'ok',
            //     response: {
            //         status: ApplicationResponseType.APPLICATION_CREATED,
            //         applicationReference: '12345',
            //     },
            // };
            setIsSubmitting(false);

            sendPageInteraction(
                {
                    type: 'savings-account',
                    decision: response.response.status === ApplicationResponseType.APPLICATION_CREATED ? 'approved' : 'declined',
                    status: response?.response?.status ?? '',
                    productCode: values?.productCode || '',
                    // eslint-disable-next-line camelcase
                    application_id: response?.response?.applicationReference ?? '',
                },
                'submitted_b2b_savings_account'
            );

            if (response?.response?.status === ApplicationResponseType.APPLICATION_DECLINED) {
                setShowError(true);
                setTimeout(() => logout(), 7500);
                return;
            }

            setStep(6);
            sessionStorage.clear();
            setTimeout(() => logout(), 7500);
        } catch (error) {
            setShowError(true);
            logout();
        }
    };

    const getLabelCallback = (key: keyof LabelsType, replacement?: string) => getLabel(labels, key, replacement);

    const renderStepContent = () => {
        const props = {
            kyc,
            step,
            setStep: (step: Steps) => {
                setStep(step);
                if (context?.user?.isAuthenticated && step < 6) {
                    sendPageInteraction({ type: 'b2b-savings-account', step, productCode: values.productCode }, 'step');
                }
            },
            products,
            showError,
            setShowError,
            fields: values,
            user: context.user,
            getValuesFromStorage,
            fetchProductInformation,
            corporateRegistryDetails,
            setManualApplicationOnly,
            getLabel: getLabelCallback,
            setCorporateRegistryDetails,
            updateFields: (updatedValues: Partial<FieldValuesType>) => setValues(previousValues => ({ ...previousValues, ...updatedValues })),
        };

        if (manualApplicationOnly) {
            return <ManualApplication {...props} />;
        }

        if (hasExistingApplication) {
            return <ApplicationExists {...props} />;
        }

        if (showError) {
            return <ApplicationError {...props} />;
        }

        switch (step) {
            case 0:
                return (
                    <Intro
                        {...props}
                        login={handleLogin}
                        isLoading={isLoading}
                        allowSoleTraders={allowSoleTraders}
                        setHasExistingApplication={setHasExistingApplication}
                        backLink={{
                            url: backLink?.url || '/sparkonto-foretag',
                            text: backLink?.title || 'Sparkonto företag',
                        }}
                    />
                );
            case 1:
                return <StepOne {...props} />;
            case 2:
                return <StepTwo {...props} />;
            case 3:
            case 3.5:
                return <StepThree {...props} selectedSignatoryGroup={selectedSignatoryGroup} setSelectedSignatoryGroup={setSelectedSignatoryGroup} />;
            case 4:
                return <StepFour {...props} />;
            case 5:
                return <StepFive {...props} onSubmit={onSubmit} />;
            case 6:
                return <Complete {...props} />;
        }
    };

    return (
        <div data-component="bank-open-savings-form-business" className="r-container r-px-4 r-pb-8">
            {step && !showError ? (
                <div className="r-mx-auto md:r-px-4">
                    <ProgressBar currentStep={step} getLabel={getLabelCallback} />
                </div>
            ) : null}
            <div ref={containerRef} className="styled-link r-mx-auto r-mt-6 md:r-mt-12">
                {renderStepContent()}
            </div>
        </div>
    );
};
