import { useMemo } from 'react';
import clsx from 'clsx';
import { PropsType, SignatoryType } from '../../types';
import { StepLayout } from './step-layout';
import { Signatory } from '../signatory';
import { FieldGroup } from '../field-group';
import { Checkbox } from '@/isomorphic/components/forms/components/checkbox';
import { validators } from '@/hooks/use-validation';
import { useLocalization } from '@/hooks/use-localization';

type Props = PropsType & {
    selectedSignatoryGroup: number | null;
    setSelectedSignatoryGroup: (value: number) => void;
};

export const StepThree = ({
    getLabel,
    updateFields,
    getValuesFromStorage,
    selectedSignatoryGroup,
    corporateRegistryDetails,
    setSelectedSignatoryGroup,
    fields,
    ...rest
}: Props) => {
    const { signatoryCombinations, keyRoles, allKeyRoles } = fields;
    const { getLocale } = useLocalization();
    const storageValue = getValuesFromStorage();

    const updateSignatory = (field: Partial<Pick<SignatoryType, 'phoneNumber' | 'email'>>, index: number) => {
        const updatedSignatories = [...signatoryCombinations];
        const updatedSignatory = { ...updatedSignatories[index], ...field };
        updatedSignatories.splice(index, 1, updatedSignatory);

        // Update also the keyRoles phoneNumber and/or email for this user as it's the same information.
        const updatedKeyRoles = [...keyRoles];
        const keyRoleIndex = updatedKeyRoles.findIndex(role => role.governmentId.id === updatedSignatory.governmentId.id);
        if (keyRoleIndex > -1) {
            updatedKeyRoles.splice(keyRoleIndex, 1, {
                ...updatedKeyRoles[keyRoleIndex],
                ...field,
            });
        }

        updateFields({ signatoryCombinations: updatedSignatories, keyRoles: updatedKeyRoles });
    };

    const onCheckboxChange = (index: number) => {
        if (selectedSignatoryGroup === index) {
            return;
        }

        const signatoryCombinations =
            corporateRegistryDetails.combinations?.[index]?.signatories?.map(({ governmentId, name, role }) => {
                const storedSignatory = storageValue?.signatoryCombinations?.find(signatory => signatory.governmentId?.id === governmentId.id);

                return {
                    role,
                    name,
                    governmentId,
                    phoneNumber: storedSignatory?.phoneNumber ?? '',
                    email: storedSignatory?.email ?? '',
                };
            }) ?? null;

        if (!signatoryCombinations) {
            return;
        }

        const keyRoles = [...allKeyRoles].filter(
            role => !!signatoryCombinations.find(signatory => signatory.governmentId.id === role.governmentId.id)
        );

        setSelectedSignatoryGroup(index);
        updateFields({ signatoryCombinations, keyRoles });
    };

    const isSubmitDisabled = useMemo(() => {
        if (rest.step === 3) {
            return selectedSignatoryGroup === null;
        }

        return !signatoryCombinations.every(
            signatory => validators.email(signatory.email) && validators.phoneMobile(signatory.phoneNumber, getLocale())
        );
    }, [signatoryCombinations, rest.step, selectedSignatoryGroup]);

    return (
        <StepLayout fields={fields} title={getLabel('signees')} isSubmitDisabled={isSubmitDisabled} getLabel={getLabel} {...rest}>
            <div
                className="r-prose -r-mt-4 r-mb-4"
                dangerouslySetInnerHTML={{ __html: rest.step === 3 ? getLabel('whoWillSignSelect') : getLabel('whoWillSign') }}
            />
            <FieldGroup className="r-space-y-4">
                {corporateRegistryDetails.combinations.map((combination, index) => (
                    <div
                        key={index}
                        className={clsx(
                            'r-rounded-lg r-p-4 r-text-sm',
                            selectedSignatoryGroup === index ? 'r-border-2 r-border-primary' : 'r-border r-border-gray-300',
                            {
                                'r-hidden': rest.step === 3.5 && selectedSignatoryGroup !== index,
                            }
                        )}
                    >
                        <Checkbox
                            value={index}
                            type="radio"
                            name={`signatory--${index}`}
                            label={combination.signatories.map(s => `${s.name.first} ${s.name.last}`).join(' | ')}
                            checked={selectedSignatoryGroup === index}
                            onChange={() => onCheckboxChange(index)}
                        />
                    </div>
                ))}
            </FieldGroup>
            {rest.step === 3.5
                ? signatoryCombinations.map((signatory, index) => (
                      <Signatory key={index} signatory={signatory} getLabel={getLabel} onUpdate={field => updateSignatory(field, index)} />
                  ))
                : null}
        </StepLayout>
    );
};
