import { useEffect, useState } from 'react';
import { useBankOpenSavingsForm } from './use-bank-open-savings-form';

export type AccountResponse = {
    accountNumber: string | null;
    clearingNumber: string | null;
};

const MAX_TRIES = 10;

export function useAccountNumber() {
    const [isFetchingAccountDetails, setIsFetchingAccountDetails] = useState(false);
    const { getApplicationLookupUrl, removeApplicationLookupUrl } = useBankOpenSavingsForm();
    const [accountDetails, setAccountDetails] = useState<AccountResponse>({
        accountNumber: null,
        clearingNumber: null,
    });

    const fetchAccountDetails = async (tries = 0) => {
        try {
            const request = await fetch('/api/bank/savings-account-form/account-details', {
                headers: {
                    'content-type': 'application/json',
                },
                credentials: 'include',
                method: 'POST',
                body: JSON.stringify({
                    apiUrl: getApplicationLookupUrl(),
                }),
            });

            if (request.status !== 200) {
                throw new Error(`Status ${request.status}`);
            }

            const response = (await request.json()) as AccountResponse;

            setAccountDetails(response);
            setIsFetchingAccountDetails(false);
        } catch (error) {
            console.error(error);

            if (tries === MAX_TRIES) {
                setIsFetchingAccountDetails(false);
                return;
            }

            setTimeout(() => {
                fetchAccountDetails(tries + 1);
            }, 2000);
        }
    };

    useEffect(() => {
        if (getApplicationLookupUrl()) {
            setIsFetchingAccountDetails(true);
            fetchAccountDetails();
        }

        return () => {
            removeApplicationLookupUrl();
        };
    }, []);

    return {
        accountDetails,
        isFetchingAccountDetails,
    };
}
