import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button } from '@/isomorphic/components/typography/button/button';
import { Product, PropsType } from '../../types';
import { Loading } from '../svg';
import { useLocalization } from '@/hooks/use-localization';
import { useGtm } from '@/hooks/use-gtm';

type Props = Pick<PropsType, 'getLabel' | 'products' | 'updateFields' | 'fields' | 'setStep'> & {
    isLoading: boolean;
};

export const ChooseProducts = ({ getLabel, isLoading, products, setStep, updateFields, fields }: Props) => {
    const { sendPageInteraction } = useGtm();
    const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);
    const { getLocale } = useLocalization();
    const handleProductChoice = (code: string) => {
        sendPageInteraction({ type: 'b2b-savings-account', productCode: code }, 'Product Select');
        updateFields({ productCode: code });
    };

    function renderInterestRate(product: Product) {
        const interestType =
            product.interest.type === 'VARIABLE' ? getLabel('variableInterestRate') || 'rörlig' : getLabel('fixedInterestRate') || 'fast';
        const interestRate = Intl.NumberFormat(getLocale(), { minimumFractionDigits: 2 }).format(product.interest.rate);

        return (
            <>
                {interestRate}% {interestType} {getLabel('interestRateLabel') || 'ränta'}
            </>
        );
    }

    useEffect(() => {
        if (fields.productCode) {
            setIsApplyButtonDisabled(false);
        }
    }, [fields.productCode]);

    return (
        <div className="r-relative r-max-w-xl r-space-y-4 r-py-4 md:r-py-16 lg:r-mx-auto">
            <h2 className="r-font-bold">{getLabel('savingsAccountBusiness')}</h2>
            <h3 className="r-pb-2 r-text-3xl r-font-bold">{getLabel('chooseSavingsAccount')}</h3>
            <div className="r-space-y-4 r-pb-2" dangerouslySetInnerHTML={{ __html: getLabel('introductoryTextProducts') }} />

            <p>
                <strong>{getLabel('productListTitle')}</strong>
            </p>

            {isLoading && (
                <div className="r-flex r-min-h-24 r-flex-col r-items-center r-justify-center" role="status">
                    <Loading />
                </div>
            )}
            {products && products.length > 0 && (
                <div className="r-flex r-flex-col r-gap-4">
                    {products.map(product => (
                        <button
                            key={product.code}
                            onClick={() => handleProductChoice(product.code)}
                            className={clsx(
                                'r-flex r-flex-col r-gap-2 r-rounded-xl r-border r-border-primary-300 r-p-4 r-ring r-transition-all r-duration-100 hover:r-ring-[2px] hover:r-ring-primary-300 focus:r-ring-[2px] focus:r-ring-primary-300',
                                product.code === fields.productCode ? 'r-ring-[2px] r-ring-primary-300' : 'r-ring-transparent'
                            )}
                        >
                            <div className="r-flex r-w-full r-flex-row r-justify-between">
                                <h4 className="r-text-xl r-font-semibold">{product.name}</h4>
                                <div className="r-flex r-items-center r-rounded-md r-bg-[#bfdfde] r-p-2 r-text-xs r-font-light r-text-primary-300">
                                    <p className="r-mb-0">{renderInterestRate(product)}</p>
                                </div>
                            </div>
                            <p className="r-text-left">{product.description}</p>
                        </button>
                    ))}
                </div>
            )}
            <div className="r-pt-4">
                <Button disabled={isApplyButtonDisabled} className="max-md:r-w-full" onClick={() => setStep(1)}>
                    {getLabel('openSavingsAccount')}
                </Button>
            </div>
        </div>
    );
};
