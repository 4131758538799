import { useLocalization } from '@/hooks/use-localization';
import { AccountResponse } from './use-account-number';
import { DepositType } from '../components/deposit';

type DepositArgs = DepositType & {
    redirectUrl: string;
    accountDetails: AccountResponse;
};

type DepositReturnType = {
    status: 'ok';
    response: {
        _links?: {
            'openbanking-redirect'?: {
                href?: string;
            };
        };
    };
};

export function useDeposit() {
    const { getCurrencyCode } = useLocalization();

    const makeInitialDeposit = async ({ redirectUrl, amount, accountDetails, transactionText = '' }: DepositArgs) => {
        if (!amount) {
            throw new Error('Invalid amount');
        }

        const request = await fetch('/api/bank/savings-account-form/openbanking/payments', {
            headers: {
                'content-type': 'application/json',
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                redirectUrl,
                clearingNumber: accountDetails.clearingNumber,
                accountNumber: accountDetails.accountNumber,
                amount: {
                    amount: parseFloat(`${amount}`),
                    currency: getCurrencyCode(),
                },
                transactionText,
            }),
        });

        if (request.status !== 200) {
            throw new Error(`Status ${request.status}`);
        }

        const response = (await request.json()) as DepositReturnType;
        return response;
    };

    return {
        makeInitialDeposit,
    };
}
