import { useEffect, useState } from 'react';
import { Product } from '../types';

type ProductRes = {
    products: Product[];
};

export const useProducts = ({ fetchProducts = false, chain = '54' }) => {
    const [isError, setIsError] = useState<string | false>(false);
    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState<Product[] | null>(null);

    async function getProducts() {
        try {
            setIsLoading(true);
            const request = await fetch(`/api/bank/corporate_deposit_orchestration_service/products?chain=${chain}`, {
                headers: {
                    'content-type': 'application/json',
                    credentials: 'include',
                },
            });
            setIsLoading(false);

            const response = (await request.json()) as {
                response?: ProductRes;
                status?: string;
            };

            if (response?.status !== 'ok') {
                throw new Error('corporate-registry-service/roles/search error');
            }

            setProducts(response.response?.products || null);
        } catch (error) {
            error instanceof Error ? setIsError(error.message) : setIsError('Error fetching products');
            setProducts(null);
        }
    }

    useEffect(() => {
        if (fetchProducts) {
            getProducts();
        }
    }, [fetchProducts]);

    return {
        isError,
        isLoading,
        products,
    };
};
