import { useMemo } from 'react';
import clsx from 'clsx';
import { UseQueryResult } from '@tanstack/react-query';
import { SearchResult } from './search-result';
import { SearchResponse } from '@/queries/search';
import { SearchResultsText } from './search-results-text';
import { useRedirects } from '@/hooks/use-redirects';

type SearchResultsProps = {
    data: UseQueryResult<SearchResponse>;
    searchTerm: string;
    close: () => void;
    page: number;
    setPage: (num: number) => void;
};

export const SearchResults = ({ data: { data }, searchTerm, close, page, setPage }: SearchResultsProps) => {
    const { getRedirectForPath } = useRedirects();

    // Remove search results that have an active redirect
    const searchResults = useMemo(
        () =>
            data?.hits?.filter(({ permalink }) => {
                if (getRedirectForPath(permalink)) {
                    return false;
                }

                if (permalink.includes('/system') || permalink.includes('/innehall-cision-2')) {
                    return false;
                }

                return true;
            }) || [],
        [data, getRedirectForPath]
    );

    if (!data || searchTerm === '' || data.nbHits === 0) {
        return null;
    }
    const numPages = Math.ceil(data.nbHits / data.limit);

    const renderButton = (_: string, arrayIndex: number) => {
        const index = arrayIndex + 1;

        return (
            <button
                key={`pagination-${index}`}
                disabled={page === index}
                onClick={() => setPage(index)}
                className={clsx(
                    'r-flex r-h-8 r-w-8 r-flex-shrink-0 r-items-center r-justify-center r-rounded-full r-transition-colors',
                    page === index ? 'r-bg-blue-100' : 'r-bg-slate-300'
                )}
            >
                {index}
            </button>
        );
    };

    return (
        <div data-component="search-results" className="r-mt-8">
            <h4>
                <SearchResultsText searchValue={searchTerm} />
            </h4>
            {searchResults.map((item, index) => (
                <SearchResult key={index} item={item} onClick={() => close()} />
            ))}
            <div className="r-overflow-auto r-pb-6">
                {numPages > 1 ? <div className="r-flex r-gap-4">{Array(numPages).join().split(',').map(renderButton)}</div> : null}
            </div>
        </div>
    );
};
