import { KycGroup } from '@/queries/kyc-survey';
import { CountryType, CustomerType, KeyRoleType, KycType, OwnerRole, OwnerType } from './types';

const KYC_GROUP = 'OWNERSHIP_AND_CONTROL';

class OwnershipError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = 'OwnershipError';
    }
}

const getLegalOrBenifialOwnerSelecion = (kycGroup: KycGroup, getValue: (id: number) => string, questionId: number) => {
    // Vilken typ är den verkliga huvudmannen?
    const question = kycGroup.questions.find(q => q.id === questionId);
    const option = question?.options?.find(o => o.text === getValue(questionId));
    if (!option) {
        throw new OwnershipError('Type of owner not found');
    }

    // 1 -> Fysisk Person, 2 -> Företag eller organisation
    return option.id === 1 ? OwnerRole.BENEFICIAL_OWNER : OwnerRole.LEGAL_BENEFICIAL_OWNER;
};

const hasMoreOwners = (kycGroup: KycGroup, getValue: (id: number) => string, questionId: number) => {
    // Finns det fler verkliga huvudmän
    const hasMoreAltOwnersOption = kycGroup.questions.find(q => q.id === questionId)?.options?.find(o => o.text === getValue(questionId));
    if (!hasMoreAltOwnersOption) {
        // This can have an empty value
        return false;
    }

    // 1 -> Ja
    return hasMoreAltOwnersOption.id === 1;
};

const getOwnerOneType = (kycGroup: KycGroup, getValue: (id: number) => string) => {
    // Hur ägs företaget?
    const ownershipOption = kycGroup.questions.find(q => q.id === 2000)?.options?.find(o => o.text === getValue(2000));
    if (!ownershipOption) {
        return null;
    }

    // Ingen äger eller kontrollerar mer än 25 procent av företaget
    if (ownershipOption.id === 1) {
        return OwnerRole.ALT_BENEFICIAL_OWNER;
    }

    return getLegalOrBenifialOwnerSelecion(kycGroup, getValue, 2201);
};

export const getOwnership = (kyc: KycType, countryCode: CountryType = 'SE', keyRoles: KeyRoleType[]) => {
    const ownerships: OwnerType[] = [];
    const getValue = (id: number) => kyc.getValue(id, KYC_GROUP);
    const kycGroup = kyc.kycSurveyData?.groups?.find(group => group.type === KYC_GROUP);
    if (!kycGroup) {
        throw new OwnershipError(`Group ${KYC_GROUP} not found`);
    }

    // There should be one initial owner
    const role = getOwnerOneType(kycGroup, getValue);

    // If not, this is a special case for 'enskilda firmor'
    if (role === null) {
        const firstKeyRole = keyRoles.length ? keyRoles[0] : null;
        return firstKeyRole
            ? ([
                  {
                      role: OwnerRole.BENEFICIAL_OWNER,
                      name: firstKeyRole.name,
                      governmentId: firstKeyRole.governmentId,
                  },
              ] as OwnerType[])
            : [];
    }

    if (role === OwnerRole.ALT_BENEFICIAL_OWNER) {
        ownerships.push({
            role,
            name: {
                first: getValue(2001),
                last: getValue(2002),
            },
            governmentId: {
                countryCode,
                id: getValue(2004),
                customerType: CustomerType.NATURAL,
            },
        });
    }
    if (role === OwnerRole.BENEFICIAL_OWNER) {
        ownerships.push({
            role,
            name: {
                first: getValue(2202),
                last: getValue(2203),
            },
            governmentId: {
                countryCode,
                id: getValue(2205),
                customerType: CustomerType.NATURAL,
            },
        });
    }
    if (role === OwnerRole.LEGAL_BENEFICIAL_OWNER) {
        ownerships.push({
            role,
            name: {
                first: getValue(2240),
                last: getValue(2240),
            },
            governmentId: {
                countryCode,
                id: getValue(2241),
                customerType: CustomerType.LEGAL,
            },
        });
    }

    // If the first owner is a company there will be only one
    if (role === OwnerRole.LEGAL_BENEFICIAL_OWNER) {
        return ownerships;
    }

    // If the first owner is an alternative befeficial owner, all subsequent owners will also be alternative
    if (role === OwnerRole.ALT_BENEFICIAL_OWNER) {
        if (!hasMoreOwners(kycGroup, getValue, 2038)) {
            // No more owners, return the one we have
            return ownerships;
        }

        ownerships.push({
            role,
            name: {
                first: getValue(2239),
                last: getValue(2240),
            },
            governmentId: {
                countryCode,
                id: getValue(2042),
                customerType: CustomerType.NATURAL,
            },
        });

        if (!hasMoreOwners(kycGroup, getValue, 2076)) {
            // No more owners, return the two we have
            return ownerships;
        }

        ownerships.push({
            role,
            name: {
                first: getValue(2077),
                last: getValue(2078),
            },
            governmentId: {
                countryCode,
                id: getValue(2080),
                customerType: CustomerType.NATURAL,
            },
        });

        // Max three owners
        return ownerships;
    }

    // From here on role === OwnerRole.BENEFICIAL_OWNER;
    if (!hasMoreOwners(kycGroup, getValue, 2249)) {
        // No more owners, return the one we have
        return ownerships;
    }

    // Get the values for the second owner
    const roleTwo = getLegalOrBenifialOwnerSelecion(kycGroup, getValue, 2250);
    if (roleTwo === OwnerRole.BENEFICIAL_OWNER) {
        ownerships.push({
            role: roleTwo,
            name: {
                first: getValue(2251),
                last: getValue(2252),
            },
            governmentId: {
                countryCode,
                id: getValue(2254),
                customerType: CustomerType.NATURAL,
            },
        });
    }
    if (roleTwo === OwnerRole.LEGAL_BENEFICIAL_OWNER) {
        ownerships.push({
            role: roleTwo,
            name: {
                first: getValue(2289),
                last: getValue(2289),
            },
            governmentId: {
                countryCode,
                id: getValue(2290),
                customerType: CustomerType.LEGAL,
            },
        });
    }

    if (!hasMoreOwners(kycGroup, getValue, 2298)) {
        // No more owners, return the two we have
        return ownerships;
    }

    // Get the values for the third and final owner
    const roleThree = getLegalOrBenifialOwnerSelecion(kycGroup, getValue, 2299);
    if (roleThree === OwnerRole.BENEFICIAL_OWNER) {
        ownerships.push({
            role: roleThree,
            name: {
                first: getValue(2300),
                last: getValue(2301),
            },
            governmentId: {
                countryCode,
                id: getValue(2303),
                customerType: CustomerType.NATURAL,
            },
        });
    }
    if (roleThree === OwnerRole.LEGAL_BENEFICIAL_OWNER) {
        ownerships.push({
            role: roleThree,
            name: {
                first: getValue(2338),
                last: getValue(2338),
            },
            governmentId: {
                countryCode,
                id: getValue(2339),
                customerType: CustomerType.LEGAL,
            },
        });
    }

    return ownerships;
};
