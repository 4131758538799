import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Labels, LinkType } from './types';
import { useLogout } from '@/hooks/use-logout';
import { useGtm } from '@/hooks/use-gtm';
import { AccountNumber } from './components/account-number';
import { Deposit, DepositType } from './components/deposit';
import { useAccountNumber } from './hooks/use-account-number';
import { useDeposit } from './hooks/use-deposit';

type Props = {
    labels: Labels;
    redirectLink?: LinkType;
    enableOpenBanking?: boolean;
    fetchAccountInformation?: boolean;
};

export const StepFive = ({ labels, redirectLink, fetchAccountInformation = false, enableOpenBanking = false }: Props) => {
    const { logout } = useLogout();
    const [searchParams] = useSearchParams();
    const { sendPageInteraction } = useGtm();
    const { makeInitialDeposit } = useDeposit();
    const [hasError, setHasError] = useState(false);
    const { accountDetails, isFetchingAccountDetails } = useAccountNumber();

    useEffect(() => {
        sendPageInteraction({
            action: 'Open savings account',
            label: 'Done',
            productCode: searchParams.has('productCode') ? searchParams.get('productCode') : '',
        });

        return () => {
            logout();
        };
    }, []);

    const onDepositSubmit = async (args: DepositType) => {
        try {
            sendPageInteraction({
                action: 'Open savings account',
                label: 'Openbanking deposit submission',
                productCode: searchParams.has('productCode') ? searchParams.get('productCode') : '',
            });
            const response = await makeInitialDeposit({ ...args, accountDetails, redirectUrl: redirectLink?.url || 'https://resursbank.se/' });
            if (response?.response?._links?.['openbanking-redirect']?.href) {
                window.location.href = response?.response?._links?.['openbanking-redirect']?.href;
                return;
            }
        } catch (error) {
            console.error(error);
        }

        setHasError(true);
    };

    return (
        <div>
            <h3 className="r-mb-2 r-text-2xl r-font-bold">{labels.applicationSentTitle}</h3>
            <p>{labels.applicationSentDescription}</p>
            <div className="r-mt-4">
                {fetchAccountInformation ? (
                    <AccountNumber labels={labels} accountDetails={accountDetails} isFetchingAccountDetails={isFetchingAccountDetails} />
                ) : null}
                {enableOpenBanking && accountDetails?.accountNumber ? (
                    <Deposit labels={labels} hasError={hasError} onSubmit={onDepositSubmit} />
                ) : null}
            </div>
        </div>
    );
};
