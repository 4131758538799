import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Checkbox } from '../../forms/components/checkbox';
import { Field } from '../../forms/components/field';
import { Button } from '../../typography/button/button';
import { Select } from '../../forms/components/select';
import { Arrow } from './components/svg';
import { PropsType } from './bank-open-savings-form';
import { AccountClearingNumber } from './components/account-clearing-number';
import { FieldsType } from './types';

export const StepTwo = ({ labels, handleChange, values, setValues, step, setStep, kycResponse, showAutoGiro = false }: PropsType) => {
    const [accountDetailsValidated, setAccountDetailsValidated] = useState(false);
    const [ddAccountDetailsValidated, setDdAccountDetailsValidated] = useState(false);

    const isSubmitDisabled = useMemo(() => {
        if (!accountDetailsValidated) {
            return true;
        }

        if (values.fixedValue) {
            return !ddAccountDetailsValidated || !values.directDebitAmount || !values.directDebitDay;
        }

        return false;
    }, [accountDetailsValidated, ddAccountDetailsValidated, values]);

    useEffect(() => {
        handleChange(false, 'fixedValue');
    }, []);

    return (
        <>
            <div
                className={clsx('r-mt-4 r-space-y-4', showAutoGiro ? 'r-pb-4' : 'r-pb-2', {
                    'r-mb-10 r-border-b': showAutoGiro,
                })}
            >
                <p className="r-text-2xl r-font-bold r-text-primary-400">
                    {step}. {showAutoGiro ? labels.formStepTwoTitle : 'Koppla konton'}
                </p>
                {showAutoGiro ? (
                    <>
                        <Checkbox
                            value={values.fixedValue}
                            checked={!!values.fixedValue}
                            onChange={v => handleChange(v, 'fixedValue')}
                            label={
                                <>
                                    <div className="styled-link r-flex r-flex-col">
                                        <p>{labels.directDebitCheckboxText}</p>
                                        <a target="_blank" href={kycResponse?.result.documents.commonTermsAutogiro}>
                                            {labels.directDebitTermsLinkText}
                                        </a>
                                    </div>
                                </>
                            }
                        />
                        {values.fixedValue && (
                            <div className="r-space-y-6">
                                <p>{labels.directDebitInfoText}</p>
                                <AccountClearingNumber
                                    bankFieldDisabled
                                    values={{
                                        clearingNumber: values.directDebitClearingNumber,
                                        accountNumber: values.directDebitAccountNumber,
                                        bankName: values.directDebitBankName,
                                    }}
                                    labels={{
                                        clearingNumber: `${labels.directDebitClearing} *`,
                                        accountNumber: `${labels.directDebitAccount} *`,
                                        bankName: `${labels.directDebitBankName} *`,
                                    }}
                                    setValues={({ accountNumber, clearingNumber, bankName }) => {
                                        const directDebitAccountValues: Pick<
                                            FieldsType,
                                            'directDebitClearingNumber' | 'directDebitAccountNumber' | 'directDebitBankName'
                                        > = {
                                            directDebitAccountNumber: accountNumber,
                                            directDebitClearingNumber: clearingNumber,
                                            directDebitBankName: bankName,
                                        };
                                        setValues({ ...values, ...directDebitAccountValues });
                                    }}
                                    accountNumberValidatedCallback={isValid => setDdAccountDetailsValidated(isValid)}
                                />
                                <Field
                                    validation={v => v?.replace(/[^\d]+/g, '')?.length > 0}
                                    type="number"
                                    value={values.directDebitAmount}
                                    onChange={v => handleChange(v, 'directDebitAmount')}
                                    label={`${labels.directDebitAmount} *`}
                                />
                                <Select
                                    searchable
                                    value={values.directDebitDay}
                                    infoText={labels.directDebitDayInfoText}
                                    onChange={v => handleChange(v as string, 'directDebitDay')}
                                    label={labels.directDebitDay}
                                    placeholder={labels.directDebitDayFirstOption}
                                    options={Array.from({ length: 28 }, (_, i) => ({ value: `${i + 1}`, label: `${i + 1}` }))}
                                />
                            </div>
                        )}
                    </>
                ) : null}
            </div>
            <div className="r-space-y-4">
                <p className="r-text-xl r-font-bold">{labels.payoutTitle}</p>
                <AccountClearingNumber
                    bankFieldDisabled
                    values={{
                        clearingNumber: values.clearingNumber,
                        accountNumber: values.accountNumber,
                        bankName: values.bankName,
                    }}
                    labels={{
                        clearingNumber: `${labels.payoutClearing} *`,
                        accountNumber: `${labels.payoutAccount} *`,
                        bankName: `${labels.payoutBankName} *`,
                    }}
                    setValues={accountValues => setValues({ ...values, ...accountValues })}
                    accountNumberValidatedCallback={isValid => setAccountDetailsValidated(isValid)}
                />
            </div>
            <div className="r-flex r-justify-center r-gap-4 md:r-gap-8">
                <Button type="secondary" fullWidth onClick={() => setStep(step - 1)}>
                    <div className="r-flex r-gap-2">
                        <Arrow className="r-w-4 r-rotate-180" />
                        <p>{labels.back}</p>
                    </div>
                </Button>
                <Button disabled={isSubmitDisabled} fullWidth onClick={() => setStep(step + 1)}>
                    <div className="r-flex r-gap-2">
                        <p>
                            {labels.continue} {step + 1}
                        </p>
                        <Arrow className="r-w-4" />
                    </div>
                </Button>
            </div>
        </>
    );
};
