import { useMemo, useState } from 'react';
import { useLocalization } from '@/hooks/use-localization';
import { validators } from '@/hooks/use-validation';
import { Select } from '@/isomorphic/components/forms/components/select';
import { Field } from '@/isomorphic/components/forms/components/field';
import { Button } from '@/isomorphic/components/typography/button/button';
import { FieldGroup } from '../field-group';
import { FieldValuesType, PropsType } from '../../types';
import { StepLayout } from './step-layout';
import { useFieldValidation } from '../../hooks/use-field-validation';
import { Kyc } from '../kyc';
import { ExplainerModal } from '../explainer-modal';
import { getImageUrl } from '@/utils/images';
import { AccountClearingNumber } from '../../../bank-open-savings-form/components/account-clearing-number';

export const StepOne = ({ kyc, getLabel, updateFields, setManualApplicationOnly, fields, ...rest }: PropsType) => {
    const { company, payoutAccount, applicant } = fields;
    const { getLanguage, getLocale } = useLocalization();
    const { validate } = useFieldValidation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAccountNumberValid, setIsAccountNumberValid] = useState(false);

    const validateField = (fieldName: string, value: string | Record<string, string>) => {
        if (typeof value === 'string') {
            return validate(fieldName, value);
        }

        return Object.keys(value).every(key => {
            if (fieldName === 'governmentId' && key === 'id') {
                return true; // We don't need to validate the orgnumber here
            }

            return validate(key, value[key as keyof typeof value]);
        });
    };

    const companyTypeSelectOptions = useMemo(() => {
        const group = kyc.kycSurveyData?.groups?.find(group => group.type === 'COMPANY');
        const companyTypes = group?.questions
            ?.find(question => question.id === 103)
            ?.options?.map(({ text: label }) => ({
                value: label,
                label,
            }));

        return companyTypes ? [{ value: '', label: `-- ${getLabel('select')} --` }, ...companyTypes] : [];
    }, [kyc]);

    const isSubmitDisabled = useMemo(() => {
        const companyValid = Object.keys(company).every(key => validateField(key, company[key as keyof typeof company]));
        const applicantValid = Object.keys(applicant).every(key => {
            if (key === 'phoneNumber') {
                return validators.phoneMobile(applicant[key], getLocale());
            }

            return validateField(key, applicant[key as keyof typeof applicant]);
        });

        if (!companyValid || !isAccountNumberValid || !applicantValid) {
            return true;
        }

        return !(kyc.isGroupValid('CORPORATE_SAVINGS') && kyc.isGroupValid('COMPANY'));
    }, [company, payoutAccount, applicant, kyc, isAccountNumberValid]);

    const updateCompanyAddress = (field: Partial<FieldValuesType['company']['address']>) =>
        updateCompany({
            address: {
                ...company.address,
                ...field,
            },
        });

    const updateApplicant = (field: Partial<FieldValuesType['applicant']>) =>
        updateFields({
            applicant: {
                ...applicant,
                ...field,
            },
        });

    const updateName = (value: string, key: 'first' | 'last' = 'first') =>
        updateApplicant({
            name: {
                ...applicant.name,
                [key]: value,
            },
        });

    const isAssociationType = (value: string) => {
        const companyGroup = kyc.kycSurveyData?.groups.find(group => group.type === 'COMPANY');
        const companyTypes = companyGroup?.questions?.find(question => question.id === 103);
        const option = companyTypes?.options?.find(option => option.text === value);

        return option?.id === 5;
    };

    const updateCompany = (field: Partial<FieldValuesType['company']>) => {
        const companyFields = { ...company, ...field };
        updateFields({ company: companyFields });

        // We need to update the KYC values manually for the company as the data is
        // essentially duplicated in KYC that we need for the application flow data.
        const groupType = 'COMPANY';
        const address = [companyFields.address.street, `${companyFields.address.zipCode} ${companyFields.address.city}`].filter(v => v).join(', ');

        const questions = {
            ...kyc.kycValues[groupType].questions,
        };
        questions[102].answer = [address];
        questions[101].answer = [companyFields.governmentId.id];
        questions[100].answer = [companyFields.organizationName];
        questions[103].answer = [companyFields.organizationType];

        kyc.setKycValues(prev => ({
            ...prev,
            [groupType]: {
                ...prev[groupType],
                questions,
            },
        }));

        // Associations (föreningar) can only apply manually
        if (isAssociationType(company.organizationName)) {
            rest.setStep(0);
            setManualApplicationOnly(true);
        }
    };

    const isFieldDisabled = (key: keyof FieldValuesType['company']) => {
        const GROUP_TYPE = 'COMPANY';

        switch (key) {
            case 'organizationName':
                return !!kyc.getValue(100, GROUP_TYPE);
            case 'address':
                return !!kyc.getValue(102, GROUP_TYPE);
            case 'organizationType':
                return !!kyc.getValue(103, GROUP_TYPE);
        }

        return false;
    };

    return (
        <>
            <StepLayout fields={fields} isSubmitDisabled={isSubmitDisabled} title={getLabel('companyInformation')} getLabel={getLabel} {...rest}>
                <div className="r-space-y-4">
                    <Select
                        label={getLabel('companyForm')}
                        value={company.organizationType}
                        options={companyTypeSelectOptions}
                        disabled={isFieldDisabled('organizationType')}
                        onChange={value => {
                            if (isAssociationType(`${value}`)) {
                                rest.setStep(0);
                                setManualApplicationOnly(true);
                            }
                            updateCompany({ organizationType: `${value}` });
                        }}
                    />
                    <FieldGroup>
                        <Field
                            grouped="top"
                            validation={v => !!v}
                            label={getLabel('address')}
                            value={company.address.street}
                            onChange={value => updateCompanyAddress({ street: value })}
                        />
                        <Field
                            grouped="middle"
                            label={getLabel('postCode')}
                            value={company.address.zipCode}
                            validation={v => validators.postcode(v, getLanguage())}
                            onChange={value => updateCompanyAddress({ zipCode: value })}
                        />
                        <Field
                            grouped="middle"
                            validation={v => !!v}
                            label={getLabel('city')}
                            value={company.address.city}
                            onChange={value => updateCompanyAddress({ city: value })}
                        />
                        <Field
                            grouped="middle"
                            value={company.email}
                            label={getLabel('email')}
                            validation={validators.email}
                            onChange={value => updateCompany({ email: value })}
                        />
                        <Field
                            grouped="bottom"
                            label={getLabel('phone')}
                            value={company.phoneNumber}
                            validation={validators.swedishPhone}
                            onChange={value => updateCompany({ phoneNumber: value })}
                        />
                    </FieldGroup>

                    <div className="r-space-y-2 r-pb-2 r-pt-4">
                        <h4 className="r-text-2xl r-font-semibold">{getLabel('customerKnowledge')}</h4>
                        <Button type="link" onClick={() => setIsModalOpen(true)}>
                            {getLabel('aboutCustomerKnowledge')}
                        </Button>
                    </div>

                    <Kyc kyc={kyc} getLabel={getLabel} type="CORPORATE_SAVINGS" />
                    <div className="r-pt-4">
                        <Kyc kyc={kyc} getLabel={getLabel} type="COMPANY" />
                    </div>

                    <div className="r-space-y-2 r-pb-2 r-pt-4">
                        <h4 className="r-text-2xl r-font-semibold">{getLabel('paymentAccount')}</h4>
                        <p>{getLabel('paymentAccountInfo')}</p>
                    </div>
                    <FieldGroup>
                        <AccountClearingNumber
                            values={{
                                ...payoutAccount,
                                bankName: payoutAccount.accountHolderBank,
                            }}
                            labels={{
                                clearingNumber: getLabel('clearingNumber'),
                                accountNumber: getLabel('accountNumber'),
                                bankName: getLabel('bankName'),
                            }}
                            setValues={({ clearingNumber, accountNumber, bankName: accountHolderBank }) =>
                                updateFields({
                                    payoutAccount: {
                                        clearingNumber,
                                        accountNumber,
                                        accountHolderBank,
                                    },
                                })
                            }
                            accountNumberValidatedCallback={setIsAccountNumberValid}
                        />
                    </FieldGroup>
                </div>
                <h4 className="r-pb-4 r-pt-8 r-text-2xl r-font-semibold">{getLabel('contactDetails')}</h4>
                <FieldGroup>
                    <Field
                        grouped="top"
                        validation={v => !!v}
                        value={applicant.name.first}
                        label={getLabel('firstName')}
                        onChange={value => updateName(value)}
                    />
                    <Field
                        grouped="middle"
                        validation={v => !!v}
                        value={applicant.name.last}
                        label={getLabel('lastName')}
                        onChange={value => updateName(value, 'last')}
                    />
                    <Field
                        grouped="middle"
                        value={applicant.email}
                        label={getLabel('yourEmail')}
                        validation={validators.email}
                        onChange={value => updateApplicant({ email: value })}
                    />
                    <Field
                        grouped="bottom"
                        label={getLabel('yourPhone')}
                        value={applicant.phoneNumber}
                        validation={value => validators.phoneMobile(value, getLocale())}
                        onChange={value => updateApplicant({ phoneNumber: value })}
                    />
                </FieldGroup>
            </StepLayout>

            <ExplainerModal
                open={isModalOpen}
                className="r-max-w-xl"
                onClose={() => setIsModalOpen(false)}
                header={getLabel('customerKnowledgeKYC')}
                imageSrc={getImageUrl('/inlaning/woman.jpg', '500xAUTO')}
                imageSrcMobile={getImageUrl('/inlaning/woman-mobile.jpg', '500xAUTO')}
            >
                <div dangerouslySetInnerHTML={{ __html: getLabel('customerKnowledgeModalExplainer') }} />
            </ExplainerModal>
        </>
    );
};
