import { useContext } from 'react';
import personnummer from 'personnummer.js';
import { validateNorwegianIdNumber } from 'norwegian-national-id-validator';
import FinnishSSN from 'finnish-ssn-2';
import cpr from 'danish-ssn';
import IBANValidator from 'iban';
//consider swapping to this instead: https://github.com/swantzter/kontonummer/releases
import kontonummer from '@24hr/kontonummer';
import { postcodeValidator } from 'postcode-validator';
import { getBankNameByClearingNumber } from './use-get-clearing-number';
import { AppContext, AppContextType } from '@/contexts/app-context';

export type ValidatorType =
    | 'email'
    | 'phone'
    | 'notEmpy'
    | 'socialSecurityNumber'
    | 'org_id'
    | 'companyRegistrationNumber'
    | 'companyIdNumber'
    | 'accountNumber'
    | 'clearingNumber';

const translateInputValidationRule = (name: ValidatorType) => {
    switch (name) {
        case 'email':
            return 'email';
        case 'phone':
            return 'phone';
        case 'socialSecurityNumber':
            return 'socialSecurityNumber';
        case 'org_id':
        case 'companyRegistrationNumber':
            return 'companyRegistrationNumber';
        case 'companyIdNumber':
            return 'companyIdNumber';
        case 'accountNumber':
            return 'bankAccountAccountNumber';
        case 'clearingNumber':
            return 'bankAccountClearingNumber';
        case 'notEmpy':
            return 'notEmpty';
        default:
            return null;
    }
};
/**
 * Validates date strings.
 *
 * For Sweden the following are valid date strings:
 * 2019-01-31, 2019.01.31, 2019/01/31
 * Single digit months and days are also ok, eg 2019/1/1
 *
 * For Denmark, Finland and Norway the following are valid date strings:
 * 31-01-2019, 31.01.2019, 31/01/2019
 * Single digit months and days are also ok, eg 1/1/2019
 */
const date = (dateString: string, language: AppContextType['language']) => {
    let year, month, day;

    if (language === 'sv') {
        const match = dateString.match(/^(\d{4})[.\-/](\d{1,2})[.\-/](\d{1,2})$/);

        if (!match) {
            return false;
        }

        year = parseInt(match[1]);
        month = parseInt(match[2]);
        day = parseInt(match[3]);
    } else {
        const match = dateString.match(/^(\d{1,2})[.\-/](\d{1,2})[.\-/](\d{4})$/);

        if (!match) {
            return false;
        }

        year = parseInt(match[3]);
        month = parseInt(match[2]);
        day = parseInt(match[1]);
    }

    if (year < 0) {
        return false;
    }

    if (month < 1 || month > 12) {
        return false;
    }

    let monthDayLimit;

    if (month === 2) {
        const leap = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
        monthDayLimit = leap ? 29 : 28;
    } else if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
        monthDayLimit = 31;
    } else {
        monthDayLimit = 30;
    }

    if (day < 1 || day > monthDayLimit) {
        return false;
    }

    return true;
};

const email = (email: string) => {
    if (!email || !email?.length) {
        return false;
    }
    // Same Regexp as https://github.com/manishsaraan/email-validator/
    const tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/; // eslint-disable-line
    const isValid = tester.test(email);
    return isValid;
};

const postcode = (postCode: string, country: AppContextType['language']) => {
    const dict = {
        sv: 'SE',
        no: 'NO',
        da: 'DK',
        fi: 'FI',
    };

    return postcodeValidator(postCode, dict[country]);
};

const phone = (phone: string, locale: string) => {
    if (!phone) {
        return false;
    }
    // This phone validators follow very specific rules given by resurs
    // It supports specific rules per country and in denmark case there are some chars that need to be escaped after specific prefixes
    // SWEDEN: country code: +46, prefixes: 70, 72, 73, 76, 79 length: 10(no country code) or 13(country code)
    // NORWAY: country code: +47, prefixes: 4, 9 length: 8(no country code) or 11(country code)
    // FINLAND: country code: +358, prefixes: 04, 050 length: 11(no country code) or 15(country code)
    // DENMARK: country code: +45, prefixes: single digits except 0 and 1 length: 8(no country code) or 11(country code)
    // Special rules for denmark:
    //      - prefix 2, 4, 5, 7 allows any following digit
    //      - prefix 3, can't be followed by 7
    //      - prefix 6, can't be followed by 7 or 8
    //      - prefix 8, can't be followed by 0, 3, 4 or 5
    //      - prefix 9, can't be followed by 0, 4, or 5

    switch (locale) {
        case 'sv-se':
            return /^(\+46|0)(70|72|73|76|79)[0-9() ]{7}$/.test(phone);
        case 'nb-no':
            return /^(\+474|\+479|4|9)[0-9() ]{7}$/.test(phone);
        case 'fi-fi':
            return /^(\+358|0)(4|50)[0-9() ]{6,8}$/.test(phone);
        case 'da-dk':
            return /(^(\+45(2|4|5|7)|2)|^(\+453(?!7)|3(?!7))|^(\+456(?!(7|8))|6(?!(7|8)))|^(\+458(?!(0|3|4|5))|8(?!(0|3|4|5)))|^(\+459(?!(0|4|5))|9(?!(0|4|5))))[0-9() ]{7,10}$/.test(
                phone
            );
        default:
            return /^\+?[-0-9() ]{6,32}$/.test(phone);
    }
};

const phoneMobile = (mobile: string, locale: string) => {
    if (locale === 'sv-se') {
        // Same validation as in business loan API, modified to only accept mobile numbers
        return /^(0|\+46|0046)[ |-]?(70|72|73|74|76|79[0-9]{0,2})([ |-]?[0-9]){7}$/.test(mobile);
    }
    return phone(mobile, locale); // Generic phone validation
};

export const swedishPhone = (value: string) => /^(0|\+46|0046)[ |-]?(200|20|70|73|76|74|[1-9][0-9]{0,2})([ |-]?[0-9]){5,8}$/.test(value);

const companyRegistrationNumber = (number: string, language: string, settings = {}) => {
    const config = {
        sv: {
            strict: false,
        },
        ...settings,
    };

    const regexs = {
        // a 8 digit number eg. 36 04 10 21 but without spaces (36041021) is ok
        da: (n: string) => /\d\d\s?\d\d\s?\d\d\s?\d\d/.test(n),
        // 7 digits, dash then a control number eg 1234567-8
        fi: (n: string) => /^\d{7}-\d$/.test(n),
        // 9 digits eg. 123 456 789, but without spaces (123456789) is ok
        no: (n: string) => /^\d{3}\s?\d{3}\s?\d{3}$/.test(n),
        // 551234-1234, but without dash (5512341234) is ok
        // In strict mode, the registration number must start with 556 or 559
        sv: (n: string) => {
            const tester = config.sv.strict ? /^55(6|9)\d{3}-?\d{4}$/ : /\d{6}-?\d{4}/;
            return personnummer.validateCIN(String(n)) && tester.test(n);
        },
    };

    try {
        return regexs[language as keyof typeof regexs](number);
    } catch (err) {
        return false;
    }
};

const socialSecurityNumber = (number: string, language: string) => {
    const ssnValidators = {
        sv: (n: string) => personnummer.validate(String(n), { strict: true }),
        se: (n: string) => personnummer.validate(String(n), { strict: true }),
        fi: (n: string) => FinnishSSN.FinnishSSN.validate(n),
        no: validateNorwegianIdNumber,
        da: cpr.isValid.bind(cpr),
    };

    // try-catch here because the danish ssn validator throws error
    // if number is less than 10 digits.
    try {
        const isValid = ssnValidators[language as keyof typeof ssnValidators](number);
        return isValid;
    } catch (err) {
        return false;
    }
};

/**
 * Validates bank account number
 *
 * The default values comes from top up bank api
 * requirements for clearing and account number
 *
 * ex: 1234-5678910
 * ! This only works for Swedish account numbers.
 *
 * @deprecated use the Resurs API instead!
 */
const bankAccountNumber = (value: string) => {
    if (!value.length) {
        return false;
    }

    // Check that value has no characters
    if (/[a-öA-Ö]+/.test(value)) {
        return false;
    }

    const validAccountNumber = kontonummer(value); // This returns an object if it's valid, else false

    return !!validAccountNumber;
};

/**
 * @deprecated use the Resurs API instead!
 */
const bankAccountClearingNumber = (value: string) => {
    if (!value.length) {
        return false;
    }

    // If clearing number is valid, it will return the name of the bank
    const bankName = getBankNameByClearingNumber(value.trim() || '');
    const isValidBank = !!bankName && typeof bankName === 'string' && bankName.length > 0;
    if (!isValidBank) {
        return false;
    }

    // Swedbank clearingnumbers that starts with an 8 should be 5 characters long.
    const clearingNumberMin = value.startsWith('8') ? 5 : 4;
    const clearingNumberMax = 6;

    if (value.length >= clearingNumberMin && value.length <= clearingNumberMax) {
        return true;
    }

    return false;
};

/**
 * @deprecated use the Resurs API instead!
 */
const bankAccountAccountNumber = (value: string) => {
    if (!value.length) {
        return false;
    }

    const accountNumberMin = 6;
    const accountNumberMax = 12;

    if (Number(value) && value.length >= accountNumberMin && value.length <= accountNumberMax) {
        return true;
    }

    return false;
};

const iban = (value: string) => IBANValidator.isValid(value);

const companyIdNumber = (value: string) => {
    const parsed = parseInt(value);
    return !isNaN(parsed) && parsed > 0;
};

const notEmpty = (value: string) => !!value;

export const validators = {
    companyRegistrationNumber,
    // eslint-disable-next-line camelcase
    org_id: companyRegistrationNumber,
    date,
    email,
    phone,
    socialSecurityNumber,
    bankAccountNumber,
    bankAccountClearingNumber,
    bankAccountAccountNumber,
    iban,
    phoneMobile,
    swedishPhone,
    companyIdNumber,
    postcode,
    notEmpty,
};

export const useValidation = () => {
    const { language } = useContext(AppContext);

    const getValidatorByInputName = (validationRule: ValidatorType) => {
        if (!validationRule) {
            return () => true;
        }

        const translatedValidationRule = translateInputValidationRule(validationRule);

        if (!translatedValidationRule) {
            return () => true;
        }

        const validator = validators[translatedValidationRule as keyof typeof validators];

        // Return a wrapper function that matches the expected type
        return (value: string) => {
            return validator(value, language);
        };
    };

    return {
        getValidatorByInputName,
    };
};
