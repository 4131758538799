import { FormEvent, useState } from 'react';
import { Field } from '@/isomorphic/components/forms/components/field';
import { Labels } from '../types';
import { Button } from '@/isomorphic/components/typography/button/button';
import { Loading } from '../../bank-open-savings-form-business/components/svg';

export type DepositType = {
    amount: string;
    transactionText: string;
};

type Props = {
    labels: Labels;
    hasError: boolean;
    onSubmit: (args: DepositType) => void;
};

export const Deposit = ({ labels, onSubmit, hasError = false }: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const [values, setValues] = useState<DepositType>({
        amount: '',
        transactionText: '',
    });

    const onFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        onSubmit(values);
    };

    return (
        <div className="r-mt-12">
            <form onSubmit={onFormSubmit} className="r-space-y-6">
                <div>
                    <h4 className="r-mb-2 r-text-xl r-font-bold">Gör en insättning direkt till ditt nya konto</h4>
                    <p>Ange önskat belopp nedan och bekräfta för att slutföra insättningen.</p>
                </div>
                {hasError ? (
                    <div className="r-mt-8 r-border-l-8 r-border-red-600 r-p-2 r-pl-4">
                        <div
                            className="r-prose prose-p:r-text-black"
                            dangerouslySetInnerHTML={{
                                __html: labels?.depositErrorText || '<p>Det uppstod ett fel när instättningen skulle genomföras!</p>',
                            }}
                        />
                    </div>
                ) : (
                    <>
                        <div className="r-space-y-4">
                            <Field label="Belopp i kr" value={values.amount} onChange={amount => setValues({ ...values, amount })} />
                            <Field
                                type="textarea"
                                value={values.transactionText}
                                label="Meddelande (valfritt)"
                                onChange={transactionText => setValues({ ...values, transactionText })}
                            />
                        </div>
                        <div className="r-flex r-items-center r-gap-4">
                            <Button isSubmit disabled={!values.amount || isLoading}>
                                Sätt in pengar
                            </Button>
                            <div>{isLoading ? <Loading /> : null}</div>
                        </div>
                    </>
                )}
            </form>
        </div>
    );
};
