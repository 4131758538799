import { useContext, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Button } from '@/isomorphic/components/typography/button/button';
import { Checkbox } from '@/isomorphic/components/forms/components/checkbox';
import { KeyRole, PropsType } from '../../types';
import { formatInterest } from '../circle';
import { Steps } from './step-layout';
import { AppContext } from '@/contexts/app-context';

export const StepFive = ({ fields, onSubmit, getLabel, setStep, products, fetchProductInformation }: PropsType & { onSubmit: () => void }) => {
    const { locale } = useContext(AppContext);
    const [checkboxes, setCheckboxes] = useState({ toc: false, guarantee: false });

    const activeProduct = useMemo(
        () => (fetchProductInformation && products?.length ? products.find(product => product?.code === fields.productCode) : null),
        [fetchProductInformation, products, fields.productCode]
    );

    const productName = useMemo(() => (activeProduct ? activeProduct.name : getLabel('savingsAccountBusiness')), [activeProduct, getLabel]);

    const interest = useMemo(() => {
        const interestRate = activeProduct
            ? Intl.NumberFormat(locale, { minimumFractionDigits: 2 }).format(activeProduct.interest.rate)
            : getLabel('interestRate');

        return formatInterest(interestRate);
    }, [activeProduct, getLabel, locale]);

    const interestType = useMemo(() => {
        if (!activeProduct) {
            return getLabel('interestLabel');
        }

        const interestType =
            activeProduct.interest.type === 'VARIABLE' ? getLabel('variableInterestRate') || 'rörlig' : getLabel('fixedInterestRate') || 'fast';

        return `<span class="r-capitalize">${interestType}</span> ${getLabel('interestRateLabel') || 'ränta'}`;
    }, [activeProduct, getLabel]);

    const productInformationLink = useMemo(() => activeProduct?._links?.savings_account_information?.href, [activeProduct]);

    const getRoles = (roles: KeyRole[]) =>
        roles
            .map(role => {
                switch (role) {
                    case KeyRole.SIGNATORY:
                        return getLabel('companyPrincipal');
                    case KeyRole.RIGHT_OF_USE:
                        return getLabel('rightOfUse');
                    case KeyRole.RIGHT_OF_INFORMATION:
                        return getLabel('rightOfInformation');
                }
            })
            .join(', ');

    const getKeyRolesRows = () => fields.keyRoles.map(keyRole => [getRoles(keyRole.roles), `${keyRole.name.first} ${keyRole.name.last}`]);

    const summaryFields = [
        {
            title: getLabel('savingsAccount'),
            step: null,
            rows: [
                [getLabel('account'), productName],
                [interestType, `<span class="r-font-normal r-italic r-pr-1">${getLabel('currently')}</span> ${interest}`],
            ],
        },
        {
            title: getLabel('companyDetails'),
            rows: [
                [getLabel('companyName'), fields.company.organizationName],
                [getLabel('orgNumber'), fields.company.governmentId.id],
            ],
        },
        {
            title: getLabel('stepFour'),
            step: 4,
            rows: getKeyRolesRows(),
        },
        {
            title: getLabel('transferAccount'),
            step: 1,
            rows: [[getLabel('transferAccount'), `${fields.payoutAccount.clearingNumber} - ${fields.payoutAccount.accountNumber}`]],
        },
    ];

    return (
        <div className="r-relative r-mx-auto r-max-w-xl r-space-y-4">
            <div className="r-space-y-4 r-pb-4 r-font-semibold">
                <h2>{getLabel('savingsAccountBusiness')}</h2>
                <h3 className="r-text-4xl">{getLabel('summany')}</h3>
            </div>

            <p className="max-md:r-hidden md:r-pb-4">{getLabel('checkDetailsText')}</p>
            <div className="r-space-y-6 r-rounded-xl r-border-2 r-border-primary-200 r-bg-white r-p-4">
                {summaryFields.map((summaryField, index) => (
                    <div key={index}>
                        <h4 className={clsx('r-font-semibold', index === 0 ? 'r-pb-4 r-text-xl' : 'r-pb-2')}>{summaryField.title}</h4>
                        {summaryField.rows.map(([key, value], rowIndex) => (
                            <div key={rowIndex} className="r-flex r-w-full r-justify-between r-gap-4 r-border-b r-border-gray-200 r-py-2">
                                <span dangerouslySetInnerHTML={{ __html: key }} />
                                <span className="styled-link r-text-right r-font-bold" dangerouslySetInnerHTML={{ __html: value }} />
                            </div>
                        ))}
                        {summaryField.step ? (
                            <Button className="r-mt-4 r-p-2" size="tiny" type="secondary" onClick={() => setStep(summaryField.step as Steps)}>
                                {getLabel('change')}
                            </Button>
                        ) : null}
                    </div>
                ))}
                <div
                    className="styled-link r-space-y-4 r-rounded-md r-bg-[#e5f2f2] r-p-4"
                    dangerouslySetInnerHTML={{ __html: getLabel('checkDetailsHelp') }}
                />
            </div>

            <div className="r-space-y-6 r-pt-4">
                <Checkbox
                    checked={checkboxes.toc}
                    label={
                        <div
                            className="styled-link"
                            dangerouslySetInnerHTML={{
                                __html: productInformationLink ? getLabel('dynamicCheckboxToc', productInformationLink) : getLabel('checkboxToc'),
                            }}
                        />
                    }
                    onChange={() => setCheckboxes({ ...checkboxes, toc: !checkboxes.toc })}
                />
                <Checkbox
                    checked={checkboxes.guarantee}
                    label={<div className="styled-link" dangerouslySetInnerHTML={{ __html: getLabel('checkboxGuarantee') }} />}
                    onChange={() => setCheckboxes({ ...checkboxes, guarantee: !checkboxes.guarantee })}
                />
            </div>

            <div className="r-flex r-gap-4 r-pt-4 max-md:r-flex-col">
                <Button className="max-md:r-order-2" fullWidth type="secondary" onClick={() => setStep(4)}>
                    {getLabel('back')}
                </Button>
                <Button className="max-md:r-order-1" disabled={!checkboxes.toc || !checkboxes.guarantee} fullWidth onClick={onSubmit}>
                    {getLabel('submitForm')}
                </Button>
            </div>
        </div>
    );
};
