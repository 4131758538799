import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import personnummer from 'personnummer.js';
import { AppContext, AppContextType } from '@/contexts/app-context';
import { Field } from '@/isomorphic/components/forms/components/field';
import { Button } from '@/isomorphic/components/typography/button/button';
import { validators } from '@/hooks/use-validation';
import { useLocalization } from '@/hooks/use-localization';
import { CountryType, KeyRole, KeyRoleType, Product, PropsType, SearchResult } from '../../types';
import { Circle } from '../circle';
import { useCorporateData } from '../../hooks/use-corporate-data';
import { LoginOverlay } from '@/isomorphic/components/login-overlay/login-overlay';
import { Loading } from '../svg';
import { getImageUrl } from '@/utils/images';
import { ChooseProducts } from './choose-product';
import { isUnder18 } from '@/utils/age-checks';

type Props = PropsType & {
    allowSoleTraders?: boolean;
    fetchProductInformation: boolean;
    user: AppContextType['user'];
    products: Product[] | null;
    isLoading: boolean;
    login: (ssn: string | number) => void;
    backLink?: {
        url: string;
        text: string;
    };
    setHasExistingApplication: Dispatch<SetStateAction<boolean>>;
};

export const Intro = ({
    kyc,
    user,
    login,
    fields,
    setStep,
    getLabel,
    setShowError,
    updateFields,
    getValuesFromStorage,
    setHasExistingApplication,
    setCorporateRegistryDetails,
    allowSoleTraders = false,
    fetchProductInformation,
    products,
    isLoading,
    backLink,
}: Props) => {
    const [govId, setGovId] = useState('');
    const context = useContext(AppContext);
    const { getLanguage, getCountry } = useLocalization();
    const [isLoadingLoginHint, setIsLoadingLoginHint] = useState(false);
    const [showChooseProducts, setShowChooseProducts] = useState(false);
    const [hasLoadedApplicantData, setHasLoadedApplicantData] = useState(false);
    const {
        getCompanyInformation,
        getApplicantInformation,
        getCorporateRegistryDetails,
        searchCorporateRegistryApplications,
        getKycSurveyData,
        hasDataFetchError,
        isFetchingData,
    } = useCorporateData();

    const validateOrgNummer = (value: string) =>
        validators.companyRegistrationNumber(value, getLanguage(), {
            sv: { strict: !allowSoleTraders },
        });

    useEffect(() => {
        if (hasDataFetchError) {
            setShowError(true);
        }
    }, [hasDataFetchError, setShowError]);

    useEffect(() => {
        if (user?.isAuthenticated && fields.applicant.governmentId.id) {
            fetchUserData(fields.applicant.governmentId.id);
        }
    }, [user?.isAuthenticated, fields.applicant.governmentId.id]);

    useEffect(() => {
        if (fields.company.governmentId.id) {
            setGovId(fields.company.governmentId.id);
        }
    }, [fields.company.governmentId.id]);

    const updateSsn = (value: string) =>
        updateFields({
            ...fields,
            applicant: {
                ...fields.applicant,
                governmentId: {
                    ...fields.applicant.governmentId,
                    id: value,
                },
            },
        });

    const capitalize = (value: string) => value?.charAt(0)?.toLocaleUpperCase() + value?.slice(1)?.toLocaleLowerCase();

    async function fetchUserData(govId: string) {
        const response = await getApplicantInformation(govId);
        setHasLoadedApplicantData(true);
        if (!response) {
            return;
        }

        const first = response?.names?.firstNames?.length ? response?.names?.firstNames.map(capitalize).join(' ') : fields.applicant.name.first;
        const last = response?.names?.familyName ? capitalize(response.names.familyName) : fields.applicant.name.last;

        const applicant = {
            ...fields.applicant,
            name: {
                first,
                last,
            },
            email: response?.email ? response.email.toLocaleLowerCase() : fields.applicant.email,
            phoneNumber: response.phoneNumbers?.[0]?.number ?? fields.applicant.phoneNumber,
        };

        updateFields({ applicant });
    }

    async function fetchData(govId: string) {
        const company = { ...fields.company };
        let allKeyRoles: KeyRoleType[] = [];
        company.governmentId.id = govId;

        const [kycSurveyData, companyInformation, corporateRegistryDetails, corporateRegistryApplications] = await Promise.all([
            getKycSurveyData(),
            getCompanyInformation(govId),
            getCorporateRegistryDetails(govId),
            searchCorporateRegistryApplications(govId),
        ]);

        if (corporateRegistryApplications === SearchResult.APPLICATION_FOUND) {
            setHasExistingApplication(true);
            return;
        }

        if (companyInformation?.addresses?.length) {
            const address = companyInformation.addresses[0];
            company.address = {
                street: address.streetNameWithNumber,
                city: address.city,
                zipCode: address.postalCode,
                // This is intentionally _not_ address.countryCode and is hard-coded
                // on purpose for now. This was resurs bank's decision!
                countryCode: address.countryCode ?? (getCountry().toLocaleUpperCase() as CountryType),
            };
        }

        if (companyInformation?.phoneNumbers?.length) {
            company.phoneNumber = companyInformation.phoneNumbers[0].number;
        }

        if (corporateRegistryDetails) {
            const { allSignatories, beneficialOwners, combinations, organizationName, organizationTypeCode } = corporateRegistryDetails;
            const corporateData = { allSignatories, beneficialOwners, combinations };
            const storageValue = getValuesFromStorage();

            if (!combinations.length || !allSignatories.length) {
                setShowError(true);
            }

            setCorporateRegistryDetails(corporateData);

            company.organizationName = organizationName;
            company.organizationType = organizationTypeCode;

            allKeyRoles = allSignatories.map(({ name, governmentId }) => {
                const storedKeyRole = storageValue?.keyRoles?.find(keyRole => keyRole.governmentId?.id === governmentId.id);

                return {
                    name,
                    governmentId,
                    isEditable: false,
                    email: storedKeyRole?.email ?? '',
                    roles: storedKeyRole?.roles ?? [KeyRole.SIGNATORY],
                    phoneNumber: storedKeyRole?.phoneNumber ?? '',
                };
            });

            const keyRoleIds = allKeyRoles.map(role => role.governmentId.id);
            const newStoredKeyRoles = storageValue?.keyRoles?.filter(keyRole => !keyRoleIds.includes(keyRole.governmentId.id)) ?? [];
            allKeyRoles = [...allKeyRoles, ...newStoredKeyRoles].filter(r => r);

            if (kycSurveyData) {
                kyc.updateKycSurvyData(kycSurveyData, corporateData, company);
            }
        }

        updateFields({ company, allKeyRoles });
    }

    const onGovIdUpdate = (govId: string) => {
        if (context?.user?.isAuthenticated && validateOrgNummer(govId)) {
            fetchData(govId);
        }
    };

    useEffect(() => {
        onGovIdUpdate(govId);
    }, [govId]);

    const isLoginButtonDisabled = useMemo(() => {
        if (isLoadingLoginHint) {
            return true;
        }

        if (getCountry() !== 'se') {
            return !validators.socialSecurityNumber(fields.applicant.governmentId.id, getLanguage());
        }

        try {
            const parsed = personnummer.parse(fields.applicant.governmentId.id);
            if (!parsed.valid) {
                return true;
            }

            if ('date' in parsed && parsed.date) {
                return isUnder18(parsed.date);
            }

            return false;
        } catch (error) {
            console.error(error);
        }

        return false;
    }, [fields.applicant.governmentId, isLoadingLoginHint, getCountry, getLanguage]);

    const hasUnder18Error = useMemo(() => {
        const parsed = personnummer.parse(fields.applicant.governmentId.id);
        if (!parsed.valid) {
            return false;
        }

        if ('date' in parsed && parsed.date) {
            return isUnder18(parsed.date);
        }

        return false;
    }, [fields.applicant.governmentId]);

    const isApplyButtonDisabled = useMemo(() => {
        if (isFetchingData) {
            return true;
        }

        return !validateOrgNummer(govId);
    }, [govId, isFetchingData]);

    if (context?.user?.isAuthenticated && !hasLoadedApplicantData) {
        return (
            <div className="r-flex r-min-h-72 r-flex-col r-items-center r-justify-center" role="status">
                <Loading />
            </div>
        );
    }

    if (!context.user?.isAuthenticated) {
        return (
            <LoginOverlay
                backLink={backLink}
                mobileImageHeader={getLabel('businessLogIn')}
                mobileImage={getImageUrl('/inlaning/woman-bike-mob.jpg', '500xAUTO')}
                desktopImage={getImageUrl('/inlaning/man-bike-large.jpeg', '1920xAUTO')}
            >
                <div className="r-space-y-4">
                    <div className="r-space-y-4 max-sm:r-hidden">
                        <h2 className="r-font-bold">{getLabel('savingsAccountBusiness')}</h2>
                        <h3 className="r-pb-2 r-text-3xl r-font-bold">{getLabel('openSavingsAccount')}</h3>
                    </div>
                    <Field
                        onChange={updateSsn}
                        label={getLabel('socialSecurityNumber')}
                        value={fields.applicant.governmentId.id}
                        validation={value => validators.socialSecurityNumber(value, getLanguage())}
                    />
                    {hasUnder18Error ? (
                        <div className="r-py-4">
                            <div
                                className="r-border-l-8 r-border-l-red-600 r-pl-4"
                                dangerouslySetInnerHTML={{ __html: getLabel('under18ErrorText') }}
                            />
                        </div>
                    ) : null}
                    <Button
                        fullWidth
                        disabled={isLoginButtonDisabled}
                        onClick={() => {
                            setIsLoadingLoginHint(true);
                            login(fields.applicant.governmentId.id);
                        }}
                        variant="bankidwhite"
                    >
                        {getLabel('identifyWithBankId')}
                    </Button>
                    <div className="r-pt-2" dangerouslySetInnerHTML={{ __html: getLabel('loginPageText') }} />
                </div>
            </LoginOverlay>
        );
    }

    if (showChooseProducts) {
        return (
            <ChooseProducts
                getLabel={getLabel}
                isLoading={isLoading}
                products={products}
                fields={fields}
                setStep={setStep}
                updateFields={updateFields}
            />
        );
    }

    return (
        <div className="r-relative r-max-w-xl r-space-y-4 r-py-4 md:r-py-16 lg:r-mx-auto">
            {!fetchProductInformation ? (
                <div className="r-w-44 md:r-absolute md:-r-right-[200px]">
                    <Circle getLabel={getLabel} fetchProductInformation={fetchProductInformation} products={products} fields={fields} />
                </div>
            ) : null}

            <h2 className="r-font-bold">{getLabel('savingsAccountBusiness')}</h2>
            <h3 className="r-pb-2 r-text-3xl r-font-bold">{getLabel('welcomeUser', fields.applicant.name.first)}</h3>
            <div className="r-space-y-4 r-pb-2" dangerouslySetInnerHTML={{ __html: getLabel('introductoryText') }} />

            <form
                className="r-space-y-2"
                onSubmit={e => {
                    e.preventDefault();
                    fetchProductInformation ? setShowChooseProducts(true) : setStep(1);
                }}
            >
                <p>
                    <strong>{getLabel('provideYourOrgNumber')}</strong>
                </p>
                <Field label={getLabel('orgNumber')} value={govId} onChange={setGovId} validation={validateOrgNummer} />
                {fields.company.organizationName ? <p className="r-font-bold r-text-primary">{fields.company.organizationName}</p> : null}
                <div className="r-pt-4">
                    <Button disabled={isApplyButtonDisabled} className="max-md:r-w-full" isSubmit>
                        {fetchProductInformation ? getLabel('chooseSavingsAccount') : getLabel('openSavingsAccount')}
                    </Button>
                </div>
            </form>
        </div>
    );
};
